import React from 'react';
import { useFormikContext } from 'formik';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { OPENAPI_SCHEMA_FORMATS } from 'core/models/openapi.models';
import { getOptionFromEnumDescription } from 'core/utilities/openapiSchema';
import FormikTextField from 'shared/FormikField/FormikTextField/FormikTextField';
import FormikSelectField from 'shared/FormikField/FormikSelectField';
import FormikMobileMaskField from 'shared/FormikField/FormikMobileMaskField/FormikMobileMaskField';
import FormikDatePicker from 'shared/FormikField/FormikDatePicker';
import FormikSelectFieldUser from 'shared/FormikField/FormikSelectField/FormikSelectFieldUser';

import { formikFieldSelectorUserSelectConfigs } from 'pages/WirelineEngagements/shared/FormikFieldSelector/constants';
import { getIsControlledFieldDisabled } from 'pages/WirelineEngagements/shared/FormikFieldSelector/utilities';
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

interface IFormikFieldSelectorProps {
  fieldName: string
  label: string
  schemaEnum?: string[] | undefined
  controlledFieldsConfigs?: IControlledFieldsConfigItem | undefined
  description?: string | undefined
  format?: OPENAPI_SCHEMA_FORMATS | undefined
  disabled?: boolean
  isTooltip?: boolean
  nullable?: boolean
  writeOnly?: boolean
}

const canceledStatus = 'CANCELED';

const FormikFieldSelector: React.FC<IFormikFieldSelectorProps> = ({
  fieldName, label, schemaEnum, controlledFieldsConfigs, description, format,
  disabled, isTooltip, nullable, writeOnly,
}) => {
  const { values } = useFormikContext<Record<string, any>>();

  const UserSelectConfig = find(formikFieldSelectorUserSelectConfigs, { fieldName });

  const isDisabledByOtherField = !!controlledFieldsConfigs && getIsControlledFieldDisabled({
    values,
    fieldName,
    controlledFieldsConfigs,
  });

  const isDisabled = disabled || isDisabledByOtherField;

  if (writeOnly) {
    return null;
  }

  if (!isEmpty(UserSelectConfig)) {
    return (
      <FormikSelectFieldUser
        {...UserSelectConfig}
        disabled={isDisabled}
      />
    );
  }

  if (format === OPENAPI_SCHEMA_FORMATS.DATE) {
    return (
      <FormikDatePicker
        fieldName={fieldName}
        label={label}
        disabled={isDisabled}
      />
    );
  }

  if (format === OPENAPI_SCHEMA_FORMATS.PHONE_NUMBER) {
    return (
      <FormikMobileMaskField
        fieldName={fieldName}
        label={label}
        disabled={isDisabled}
      />
    );
  }

  if (schemaEnum?.length && description) {
    const options = getOptionFromEnumDescription(description);
    const value = get(values, fieldName);

    const updatedOptions = options.map((option) => {
      if (option.value === canceledStatus) {
        return { ...option, disabled: true };
      }

      return option;
    });

    return (
      <FormikSelectField
        // key is essential here, otherwise the select field will not update
        key={value}
        fieldName={fieldName}
        options={updatedOptions}
        label={label}
        disabled={isDisabled}
        nullable={nullable as boolean}
      />
    );
  }

  return (
    <FormikTextField
      fieldName={fieldName}
      label={label}
      disabled={isDisabled}
      isTooltip={isTooltip as boolean}
    />
  );
};

FormikFieldSelector.defaultProps = {
  schemaEnum: undefined,
  description: undefined,
  format: undefined,
  disabled: false,
  isTooltip: false,
  nullable: false,
  writeOnly: false,
  controlledFieldsConfigs: undefined,
};

export default FormikFieldSelector;

import get from 'lodash/get';
// eslint-disable-next-line no-unused-vars
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

export const getIsControlledFieldDisabled = ({
  values,
  fieldName,
  controlledFieldsConfigs,
} : {
  fieldName: string,
  values: Record<string, any>,
  controlledFieldsConfigs: IControlledFieldsConfigItem,
}) => {
  const fieldConfigs = controlledFieldsConfigs?.[fieldName];
  if (!fieldConfigs?.length) { return false; }

  return fieldConfigs.every(({ switchFieldName, switchEnabledValues }) => {
    if (!switchFieldName || !switchEnabledValues) {
      return false;
    }

    const switchFieldValue = get(values, switchFieldName);
    return !switchEnabledValues.includes(switchFieldValue);
  });
};

import styles from 'assets/styles/_resources.scss';

export const colorsOfengagementTypeCharts = [
  styles.redColor100,
  styles.tealColor500,
  styles.primaryColor,
  styles.darkColor100,
  styles.secondaryColor200,
];

export const colorsOfTotalOrderTypeCharts = [
  styles.tealColor500,
  styles.primaryColor,
];

export const ENGAGEMENT_TYPES_LABELS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progres',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  DECLINED: 'Declined',
};

export const REQUEST_TYPE_LABELS = {
  PENDING: 'Pending',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  DECLINED: 'Declined',
};

export const TOTAL_ORDER_TYPE_LABELS = {
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
};

export const TIMELINE_TO_COMPLETION_STATUS_TYPE_LABELS = {
  WITHIN_TIMEFRAME: 'Within timeframe',
  OUTSIDE_TIMEFRAME: 'Outside timeframe',
};

export const notifierMessage = {
  engagementType: {
    error: 'Failed to complete your request for Engagement type, please try again later.',
  },
};

import React from 'react';
import { contractFormPath } from 'core/routing/paths';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import { RenewalStatusSow } from '@EngagementsRenewalSowData/renewalSowData.models';
import EscalationSupportView from 'pages/WirelineEngagements/shared/EscalationSupportView';
import RenewalSowDataTableDateColumnItem from '@EngagementsRenewalSowData/RenewalSowDataTableDateColumnItem';
import {
  roleStatusLabels,
  requestStatusLabels,
  providedBodyCellStyle,
  providedHeadCellStyle,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import { ROLE_STATUS } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import {
  contractSuportValues,
  billingSupportLabels,
  billingSupportValues,
  sellerRequestTypeValues,
  sellerRequestTypeLabels,
  contractOrderSupportLabels,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

export const renewalSowDataTitle = 'Renewal SOW Data';

export const disabledCheckboxRowKeyRenewalSow = 'renewalEngagementAcceptedDate';

export const columns = [
  {
    accessorKey: 'requestQuoteDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'provideQuoteDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest={false} requestProvidedDate={renderedCellValue as string} />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'requestContractDatetime',
    Cell: ({ renderedCellValue, row }) => renderedCellValue && (
      <RenewalSowDataTableDateColumnItem
        isRequest
        requestProvidedDate={renderedCellValue as string}
        viewPath={`${contractFormPath}/${row.original.contractBundleId}`}
      />
    ),
  },
  {
    accessorKey: 'provideContractDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest={false} requestProvidedDate={renderedCellValue as string} />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'billingEngagementAcceptedDate',
    minWidth: '100px',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
    <RequestProvidedDate isRequest requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'hasEscalationRequests',
    Cell: ({ row: { original } }) => (
      <EscalationSupportView
        product={original.product as string}
        customerCompanyName={original.customerCompanyName as string}
        hasEscalationRequests={original.hasEscalationRequests as boolean}
      />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'sellerRequestType',
    Cell: ({ renderedCellValue }) => (
      sellerRequestTypeLabels[renderedCellValue as sellerRequestTypeValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'contractOrderSupport',
    Cell: ({ renderedCellValue }) => (
      contractOrderSupportLabels[renderedCellValue as contractSuportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSupport',
    Cell: ({ renderedCellValue }) => (
      billingSupportLabels[renderedCellValue as billingSupportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSpecialistStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'requestQuoteStatus',
    Cell: ({ renderedCellValue }) => (
      requestStatusLabels[renderedCellValue] || renderedCellValue
    ),
  },
  {
    accessorKey: 'requestContractStatus',
    Cell: ({ renderedCellValue }) => (
      requestStatusLabels[renderedCellValue] || renderedCellValue
    ),
  },
];

export const dynamicRenewalProductSchemaName = 'DynamicRenewalProductsSerializerSchema';

export const renewalStatusLabels = {
  [RenewalStatusSow.IN_PROGRESS]: 'In Progress',
  [RenewalStatusSow.DECLINED]: 'Declined',
  [RenewalStatusSow.RENEWED]: 'Renewed',
};

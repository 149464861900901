import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form } from 'formik';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IOption } from 'shared/FormikField/FormikField.model';
// eslint-disable-next-line no-unused-vars
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { IServerValidationErrorMessage } from 'core/services/HTTPService.models';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import EscalationSupportModal from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupporModal';
import { postEscalationSupportAction } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupport';
import { EscalationSupportFormSchema } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupportFormSchema';

type IEscalationSupportRowProp = IRenewalSowDataItem
  | ISowDataListItem
  | IActiveNumbersItem
  | IContract
  | null
  | undefined;
interface IEscalationSupportModalFormProps {
  escalationSupportRow: IEscalationSupportRowProp
  escalationSupportRowId: string | number | null | undefined
  cleanEscalationSupportRow: () => void
  requestParams?: object
  escalationTypeOptions: IOption[]
  isVisibleLocation?: boolean
}

const EscalationSupportModalForm: React.FC<IEscalationSupportModalFormProps> = ({
  requestParams,
  isVisibleLocation,
  escalationSupportRow,
  escalationTypeOptions,
  escalationSupportRowId,
  cleanEscalationSupportRow,
}) => {
  const dispatch = useAppDispatch();
  const { getTableData } = useTableContext();
  const { showErrorNotifier } = useNotifiers();

  const isLoading = useAppSelector(
    (state) => state.wirelineEngagements.escalationSupport.isLoading,
  );

  const initialValues = EscalationSupportFormSchema.getDefault();

  const onSubmit = async (params, { setErrors, resetForm }) => {
    if (!escalationSupportRowId) return;

    try {
      const requestData = {
        ...params,
        ...requestParams,
      };

      await dispatch(postEscalationSupportAction({
        id: escalationSupportRowId,
        params: requestData,
      })).unwrap();

      cleanEscalationSupportRow();
      getTableData();
      resetForm();
    } catch (error) {
      const definedError = error as IServerValidationErrorMessage;
      const errorData = definedError?.response?.data;

      setErrors(errorData);
      if (errorData?.validationError?.length) {
        showErrorNotifier(errorData?.validationError[0], error);
      }
    }
  };

  if (isEmpty(escalationSupportRow)) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={EscalationSupportFormSchema}
    >
      <Form>
        <EscalationSupportModal
          isLoading={isLoading}
          isVisibleLocation={isVisibleLocation}
          escalationSupportRow={escalationSupportRow}
          escalationTypeOptions={escalationTypeOptions}
          escalationSupportRowId={escalationSupportRowId}
          cleanEscalationSupportRow={cleanEscalationSupportRow}
        />
      </Form>
    </Formik>
  );
};

EscalationSupportModalForm.defaultProps = {
  requestParams: {},
  isVisibleLocation: true,
};

export default EscalationSupportModalForm;

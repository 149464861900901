import React, { ReactElement } from 'react';
import { IMessage } from 'shared/ChatWidget';

interface IChatWindowProps {
  message: IMessage
  botIcon?: ReactElement | undefined
}

const Message: React.FC<IChatWindowProps> = ({
  message,
  botIcon,
}) => {
  const {
    text, isBot, link, linkText,
  } = message;

  if (isBot) {
    return (
      <div className="cw-message cw-bot-message">
        {botIcon
          && (
            <div className="cw-bot-icon">
              {botIcon}
            </div>
          )}
        <div className="cw-message-text">
          <span>{text}</span>
          {link && (
            <p>
              <a
                target="_blank"
                href={link}
                rel="noreferrer"
                className="cw-message-link"
              >
                {linkText || link}
              </a>
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="cw-message cw-user-message cw-message-text">
      <span>{text}</span>
    </div>
  );
};

Message.defaultProps = {
  botIcon: undefined,
};

export default Message;

export enum TimelineToCompletionChart {
    WITHIN_TIMEFRAME = 'WITHIN_TIMEFRAME',
    OUTSIDE_TIMEFRAME = 'OUTSIDE_TIMEFRAME',
}

interface ITimelineToCompletionItemValue {
    [TimelineToCompletionChart.WITHIN_TIMEFRAME]?: number;
    [TimelineToCompletionChart.OUTSIDE_TIMEFRAME]?: number;
}

export interface ITimelineToCompletionItem {
    // eslint-disable-next-line no-restricted-globals
    name: string;
    value: ITimelineToCompletionItemValue;
}

/* eslint-disable no-param-reassign */
import { postEscalationSupport } from 'core/api/sales.api/sales.api';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import { IEscalationSupportParams } from '@EngagementsSowData/salesSow.model';

export interface IState {
  isLoading: boolean
}

const initialState: IState = {
  isLoading: false,
};

export const postEscalationSupportAction = createAsyncThunk(
  'postEscalationSupportAction',
  async ({ id, params }: { id: string | number, params: IEscalationSupportParams },
    { rejectWithValue }) => {
    try {
      await postEscalationSupport(id, params);
    } catch (error) {
      return rejectWithValue(error);
    }
  });

const escalationSupportSlice = createSlice({
  name: 'escalationSupport',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        postEscalationSupportAction.pending,
        (state) => {
          state.isLoading = true;
        })
      .addMatcher(
        isAnyOf(postEscalationSupportAction.rejected, postEscalationSupportAction.fulfilled),
        (state) => {
          state.isLoading = false;
        },
      );
  },
});

export const escalationSupportReducer = escalationSupportSlice.reducer;

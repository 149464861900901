import React from 'react';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import {
  escalationTypeCMTableOptions,
} from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/constants';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';

const EscalationSupportModal = () => {
  const {
    activeRow,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<ISowDataListItem | IContract>();

  const { isEscalationSupportMode } = getTableRowModeBoolean(activeRowMode);
  const escalationSupportRow = isEscalationSupportMode ? activeRow : undefined;

  const escalationSupportRowId = (activeRow as IContract)?.order;

  return (
    <EscalationSupportModalForm
      isVisibleLocation={false}
      cleanEscalationSupportRow={cleanActiveRow}
      escalationSupportRow={escalationSupportRow}
      escalationSupportRowId={escalationSupportRowId}
      escalationTypeOptions={escalationTypeCMTableOptions}
    />
  );
};

export default EscalationSupportModal;

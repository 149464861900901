import React from 'react';
import { map } from 'lodash';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { LabelPosition } from 'core/models/Recharts.models';
import {
  colorsOfTotalOrderTypeCharts,
  TOTAL_ORDER_TYPE_LABELS,
} from '@dashboardWirelineContractSpecialistDepartment/constants';
import {
  mockTotalOrderTypeData,
} from '@dashboardWirelineContractSpecialistDepartment/TotalOrderTypeChart/mockTotalOrderTypeData';

const TotalOrderTypeChart = () => {
  const barLabel = {
    label: { position: LabelPosition.TOP },
  };

  const formattedData = map(mockTotalOrderTypeData, (item) => ({
    xDateKey: item.name,
    ...item.value,
  }));

  return (
    <DashboardBarChart
      data={formattedData}
      colors={colorsOfTotalOrderTypeCharts}
      fields={TOTAL_ORDER_TYPE_LABELS}
      isLoading={false}
      title="Total Order Type"
      barLabel={barLabel}
      needInsideLabel
    />
  );
};

export default TotalOrderTypeChart;

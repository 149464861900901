import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import {
  ACTIONS_WIRELINE_ENGAGEMENT_PATHS,
  ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS,
} from 'core/models/actionsPermissions';

const { ORDER_CREATE } = ACTIONS_WIRELINE_ENGAGEMENT_PATHS;
const {
  QUOTE_CREATE,
  ORDER_DATA_GET,
  CONTRACT_CREATE,
  ORDER_PARTIAL_EDIT,
  CANCEL_ORDER_CREATE,
  COMMENTS_GENERAL_GET,
  COMMENTS_BILLING_GET,
  DECLINE_RENEWAL_CREATE,
  ENGAGEMENT_ACCEPT_CREATE,
  ESCALATION_SUPPORT_CREATE,
  EXPORT_RENEWAL_SOW_TABLE_GET,
} = ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS;

const actionsPaths = {
  isAvailableCreateOrder: ORDER_CREATE,
  isAvailableQuoteRequest: QUOTE_CREATE,
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableEditOrder: ORDER_PARTIAL_EDIT,
  isAvailableCancelOrder: CANCEL_ORDER_CREATE,
  isAvailableContractRequest: CONTRACT_CREATE,
  isAvailableDeclineRenewal: DECLINE_RENEWAL_CREATE,
  isAvailableGetGeneralComments: COMMENTS_GENERAL_GET,
  isAvailableGetBillingComments: COMMENTS_BILLING_GET,
  isAvailableEngagementAccept: ENGAGEMENT_ACCEPT_CREATE,
  isAvailableExportTableData: EXPORT_RENEWAL_SOW_TABLE_GET,
  isAvailableCreateEscalationSupport: ESCALATION_SUPPORT_CREATE,
};

export const useRenewalSowPermissions = () => {
  const {
    isAvailableEditOrder,
    isAvailableCancelOrder,
    isAvailableCreateOrder,
    isAvailableGetOrderData,
    isAvailableQuoteRequest,
    isAvailableDeclineRenewal,
    isAvailableContractRequest,
    isAvailableExportTableData,
    isAvailableEngagementAccept,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableCreateEscalationSupport,
  } = useActionsPermissions(actionsPaths);

  const isAvailableGetComments = isAvailableGetGeneralComments || isAvailableGetBillingComments;

  const isAvailableMoreActions = isAvailableGetOrderData
    || isAvailableGetComments
    || isAvailableCancelOrder
    || isAvailableDeclineRenewal
    || isAvailableCreateEscalationSupport;

  const isAvailableSomeRowAction = isAvailableEditOrder || isAvailableMoreActions;
  const isAvailableTwoRowActions = isAvailableEditOrder && isAvailableMoreActions;

  const isAvailableRequestActions = isAvailableQuoteRequest || isAvailableContractRequest;
  const isAvailableTableActions = isAvailableEngagementAccept || isAvailableRequestActions;

  return {
    isAvailableEditOrder,
    isAvailableCreateOrder,
    isAvailableCancelOrder,
    isAvailableGetComments,
    isAvailableGetOrderData,
    isAvailableTableActions,
    isAvailableQuoteRequest,
    isAvailableSomeRowAction,
    isAvailableTwoRowActions,
    isAvailableRequestActions,
    isAvailableDeclineRenewal,
    isAvailableExportTableData,
    isAvailableEngagementAccept,
    isAvailableGetGeneralComments,
    isAvailableGetBillingComments,
    isAvailableCreateEscalationSupport,
  };
};

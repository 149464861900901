import React from 'react';
import {
  Cell, Pie, PieChart, Tooltip,
} from 'recharts';
import cx from 'classnames';
import { useMediaQuery, useTheme } from '@mui/material';
import ChartTooltip from 'pages/Dashboard/shared/ChartTooltip';
import ChartLegend from 'pages/Dashboard/shared/ChartLegend';
import styles from 'assets/styles/_resources.scss';
import { IChartItem } from 'core/models/Recharts.models';
import DashboardPieChartSkeleton from 'pages/Dashboard/shared/DashboardPieChart/DashboardPieChartSkeleton';

import './DashboardPieChart.scss';

interface IDashboardPieChart {
  colors: string[];
  title?: string;
  innerRadius?: number;
  data: IChartItem[];
  paddingAngle?: number;
  isLoading?: boolean;
  className?: string;
  showDecimals?: boolean;
}

const DashboardPieChart: React.FC<IDashboardPieChart> = ({
  data,
  colors,
  innerRadius,
  paddingAngle,
  title,
  isLoading,
  className,
  showDecimals,
}) => {
  const classNameCombined = cx('dashboard-pie-chart', className);
  const sumOfChartArray = data?.reduce((acc, value) => acc + Number(value.value), 0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isLoading) {
    return (
      <div className="dashboard-pie-chart">
        <h3 className="dashboard-pie-chart__title">{title}</h3>
        <DashboardPieChartSkeleton isMobile={isMobile} />
      </div>
    );
  }

  const pieChartContent = () => {
    if (sumOfChartArray === 0) {
      return (
        <Pie
          data={[{ name: 'No data', value: 1 }]}
          dataKey="value"
          cx={100}
          cy={100}
          outerRadius={80}
          innerRadius={innerRadius}
          fill={styles.lightColor100}
          stroke="none"
        />
      );
    }
    return (
      <>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={isMobile ? 75 : 80}
          fill="#8884d8"
          paddingAngle={paddingAngle}
          dataKey="value"
          stroke="none"
          startAngle={-270}
        >
          {data?.map((entry, index) => (
            <Cell key={entry.name} fill={colors[index % colors.length]} stroke="none" />
          ))}
        </Pie>
        <Tooltip content={<ChartTooltip />} />
      </>
    );
  };

  return (
    <div className={classNameCombined}>
      <h3 className="dashboard-pie-chart__title">{title}</h3>
      <div className="dashboard-pie-chart__wrap">
        <PieChart width={isMobile ? 150 : 250} height={isMobile ? 150 : 200}>
          {pieChartContent()}
        </PieChart>
        <ChartLegend data={data} colors={colors} showDecimals={showDecimals} />
      </div>
    </div>
  );
};

DashboardPieChart.defaultProps = {
  paddingAngle: 0,
  innerRadius: 0,
  title: '',
  isLoading: false,
  className: '',
  showDecimals: false,
};

export default DashboardPieChart;

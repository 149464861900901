import React from 'react';
import { useParams } from 'react-router-dom';
import { firstPage } from 'shared/Table/constants';
import { useTableContext } from 'shared/Table/TableContext';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { useUserPermissions } from '@EngagementsSowData/useUserPermissions';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import DuplicateOrderModal from 'pages/WirelineEngagements/shared/DuplicateOrderModal';
import ActionsModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal';
import { isOrderAttTSD } from 'pages/WirelineEngagements/WirelineEngagementsTables/utilities';
import DataRowSidebar from '@EngagementsSowData/WirelineEngagementsSowDataModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsSowData/WirelineEngagementsSowDataModals/CommentsSidebar';
import CancelOrderModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/CancelOrderModal';
import EditRowSidebarForm from '@EngagementsSowData/WirelineEngagementsSowDataModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsSowData/WirelineEngagementsSowDataModals/EscalationSupportModal';

const WirelineEngagementsSowDataModals = ({
  orderAction,
  selectedOrders,
  addOrderModalType,
  isActionsModalOpen,
  setAddOrderModalType,
  setIsActionsModalOpen,
  isAvailableCreateOrder,
}) => {
  const { customerId } = useParams();
  const { setPagination, getTableData } = useTableContext();
  const {
    activeRow,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<ISowDataListItem>();

  const { isAvailableOrderDataSidebar } = useUserPermissions();

  const isTsdAtt = isOrderAttTSD(activeRow);
  const isVisibleOrderDataSidebar = isTsdAtt && isAvailableOrderDataSidebar;

  const onAddProductSuccessCallback = () => {
    setPagination((paging) => ({
      ...paging,
      pageIndex: firstPage,
    }));

    getTableData();
  };

  return (
    <>
      <EditRowSidebarForm />
      <EscalationSupportModal />
      <CancelOrderModal />
      <DuplicateOrderModal
        activeRowMode={activeRowMode}
        activeRow={activeRow}
        cleanActiveRow={cleanActiveRow}
      />
      {
        isAvailableCreateOrder && (
          <AddOrderModalForm
            customerId={Number(customerId)}
            orderModalType={addOrderModalType}
            setAddOrderModalType={setAddOrderModalType}
            onSubmitSuccessCallback={onAddProductSuccessCallback}
          />
        )
      }

      <ActionsModal
        orderAction={orderAction}
        selectedOrders={selectedOrders}
        isModalOpen={isActionsModalOpen}
        setIsActionsModalOpen={setIsActionsModalOpen}
      />
      <CommentsSidebar />
      {isVisibleOrderDataSidebar
         && <DataRowSidebar />}
    </>
  );
};

export default WirelineEngagementsSowDataModals;

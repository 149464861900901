import styles from 'assets/styles/_resources.scss';
import { EngagementTypeForChart } from '@dashboardWirelineInsideSalesDepartment/InsideSalesDepartment.models';

export const colorsOfInsideSalesCharts = [
  styles.redColor100,
  styles.tealColor500,
  styles.primaryColor,
  styles.darkColor200,
];

export const colorsOfInsideSalesByUsersCharts = [
  styles.tealColor500,
  styles.primaryColor,
];

export const notifierMessage = {
  requestStatus: {
    error: 'Failed to complete your request for Request Status, please try again later.',
  },
  insideSalesUsers: {
    error: 'Failed to complete your request for Users, please try again later.',
  },
};

export const byUsersChartFields = {
  [EngagementTypeForChart.IN_PROGRESS]: 'In Progress',
  [EngagementTypeForChart.COMPLETED]: 'Completed',
};

import React, { useEffect } from 'react';
import map from 'lodash/map';

import { getMonthName } from 'pages/Dashboard/shared/utilities';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { colorsOfTotalCharts, totalChartFields } from '@dashboardWirelineDepartments/shared/constants';
// eslint-disable-next-line no-unused-vars
import { IEngagementsOrdersByPeriodItem, IEngagementsOrdersByPeriodItemChart } from 'pages/Dashboard/Dashboard.models';
import { TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

interface IEngagementsByPeriodChartProps {
  isLoading: boolean;
  list: IEngagementsOrdersByPeriodItem[];
  onPeriodChange: TSetPeriod;
}

const EngagementsByPeriodChart: React.FC<IEngagementsByPeriodChartProps> = ({
  list,
  isLoading,
  onPeriodChange,
}) => {
  const data = map(list, (item) => ({
    ...item,
    xDateKey: getMonthName(item?.month),
  }));

  useEffect(() => {
    const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.THREE_MONTHS);
    onPeriodChange(defaultPeriod);
  }, []);

  return (
    <DashboardBarChart<IEngagementsOrdersByPeriodItemChart>
      data={data}
      isLoading={isLoading}
      colors={colorsOfTotalCharts}
      fields={totalChartFields}
      title="Quantity By Period"
      xAxisLabel="MON"
      onPeriodChange={onPeriodChange}
      showPeriod
    />
  );
};

export default EngagementsByPeriodChart;

import {
  EngagementTypeForChart,
  // eslint-disable-next-line no-unused-vars
  IRequestsTypeItem,
} from '@dashboardWirelineContractSpecialistDepartment/GetRequestsTypeChart/constants';

export const mockRequestsTypeData: IRequestsTypeItem[] = [
  {
    name: 'Quote',
    value: {
      [EngagementTypeForChart.IN_PROGRESS]: 1,
    },
  },
  {
    name: 'Contract',
    value: {
      [EngagementTypeForChart.COMPLETED]: 9,
      [EngagementTypeForChart.IN_PROGRESS]: 4,
    },
  },
  {
    name: 'Order',
    value: {
      [EngagementTypeForChart.IN_PROGRESS]: 1,
    },
  },
  {
    name: 'Renewal quote',
    value: {
      [EngagementTypeForChart.IN_PROGRESS]: 2,
    },
  },
  {
    name: 'Renewal contract',
    value: {
      [EngagementTypeForChart.DECLINED]: 3,
      [EngagementTypeForChart.IN_PROGRESS]: 4,
    },
  },
];

import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_CONTRACT_MANAGEMENT_PATHS } from 'core/models/actionsPermissions';

const {
  ORDER_DATA_GET,
  ATTACHMENTS_GET,
  COMMENTS_BILLING_GET,
  COMMENTS_GENERAL_GET,
  REQUEST_ACCEPTED_CREATE,
  REQUEST_COMPLETED_CREATE,
  CONTRACT_SPECIALIST_CREATE,
  CONTRACT_MANAGEMENT_PARTIAL_EDIT,
  ESCALATION_SUPPORT_CREATE,
} = ACTIONS_CONTRACT_MANAGEMENT_PATHS;

const actionsPaths = {
  isAvailableGetOrderData: ORDER_DATA_GET,
  isAvailableGetAttachments: ATTACHMENTS_GET,
  isAvailableGetCommentsGeneral: COMMENTS_GENERAL_GET,
  isAvailableGetCommentsBilling: COMMENTS_BILLING_GET,
  isAvailableRequestAccepted: REQUEST_ACCEPTED_CREATE,
  isAvailableRequestCompleted: REQUEST_COMPLETED_CREATE,
  isAvailableEditOrder: CONTRACT_MANAGEMENT_PARTIAL_EDIT,
  isAvailableContractSpecialist: CONTRACT_SPECIALIST_CREATE,
  isAvailableGetEscalationSupport: ESCALATION_SUPPORT_CREATE,
};

export const useContractManagementPermissions = () => {
  const {
    isAvailableEditOrder,
    isAvailableGetOrderData,
    isAvailableGetAttachments,
    isAvailableRequestAccepted,
    isAvailableRequestCompleted,
    isAvailableGetCommentsBilling,
    isAvailableGetCommentsGeneral,
    isAvailableContractSpecialist,
    isAvailableGetEscalationSupport,
  } = useActionsPermissions(actionsPaths);

  const isAvailableGetComments = isAvailableGetCommentsGeneral || isAvailableGetCommentsBilling;
  const isAvailableGetLogAndFiles = isAvailableGetAttachments || isAvailableGetComments;

  const isAvailableMoreActions = isAvailableGetOrderData || isAvailableGetLogAndFiles;

  const isAvailableSomeRowAction = isAvailableEditOrder || isAvailableMoreActions;
  const isAvailableTwoRowActions = isAvailableEditOrder && isAvailableMoreActions;

  const isAvailableRequestsActions = isAvailableRequestAccepted
    || isAvailableRequestCompleted
    || isAvailableContractSpecialist;

  return {
    isAvailableEditOrder,
    isAvailableGetComments,
    isAvailableGetOrderData,
    isAvailableSomeRowAction,
    isAvailableTwoRowActions,
    isAvailableGetAttachments,
    isAvailableGetLogAndFiles,
    isAvailableRequestsActions,
    isAvailableRequestAccepted,
    isAvailableRequestCompleted,
    isAvailableContractSpecialist,
    isAvailableGetEscalationSupport,
  };
};

import {
  getBookingCSDataRequest,
  getBookingCSDataSuccess,
  getBookingCSDataFailure,
} from './GetBookingCSData';
// eslint-disable-next-line no-unused-vars
import getBookingCSData from './api.service';

const getBookingCSDataAction = (
  // eslint-disable-next-line no-unused-vars
  controller,
  // eslint-disable-next-line no-unused-vars
  params: {
      page: number;
      page_size: number;
      search: string;
      ordering?: string | undefined;
    }) => async (dispatch) => {
  const { signal } = controller;

  try {
    dispatch(getBookingCSDataRequest());

    const { data } = await getBookingCSData(signal, params);
    dispatch(getBookingCSDataSuccess(data));
  } catch (error) {
    dispatch(getBookingCSDataFailure(error));
  }
};

export default getBookingCSDataAction;

import { useRef, useCallback } from 'react';

const useCancelableRequest = (HTTPService) => {
  const controllerRef = useRef(null);

  const createNewController = useCallback(() => {
    if (controllerRef.current) {
      HTTPService.cancelRequest(controllerRef.current);
    }

    const newController = HTTPService.getController();
    controllerRef.current = newController;

    return newController;
  }, [HTTPService]);

  const cancelRequest = useCallback(() => {
    if (controllerRef.current) {
      HTTPService.cancelRequest(controllerRef.current);
      controllerRef.current = null;
    }
  }, [HTTPService]);

  return {
    controller: controllerRef.current,
    cancelRequest,
    createNewController,
  };
};

export default useCancelableRequest;

import React, { memo, useMemo } from 'react';
import { compact } from 'lodash';
import paths from 'core/routing/paths';
import { GroupMenuItems } from 'shared/ButtonMenu';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS } from 'core/models/actionsPermissions';
import { validateOrderBCPFields, validateCommonOrderField } from '@EngagementsRenewalSowData/RenewalSowOrdersActions/utilities';

const { QUOTE_CREATE, CONTRACT_CREATE } = ACTIONS_WIRELINE_ENGAGEMENT_RENEWAL_SOW_PATHS;

const actionsPaths = {
  isAvailableQuoteRequest: QUOTE_CREATE,
  isAvailableContractRequest: CONTRACT_CREATE,
};

interface IRequestActionsItemsProps {
  selectedOrders: IRenewalSowDataItem[]
  setIsModalOpen: (isOpen: boolean) => void
}

const RequestActionsItems: React.FC<IRequestActionsItemsProps> = ({
  selectedOrders,
  setIsModalOpen,
}) => {
  const navigate = useNavigate();

  const {
    isAvailableQuoteRequest,
    isAvailableContractRequest,
  } = useActionsPermissions(actionsPaths);

  const orderIds = selectedOrders.map(({ id }) => id).join(',');

  const { isDisabledRequestQuote, isDisabledRequestContract } = useMemo(
    () => {
      const everyOrdersHasRequestQuoteDatetime = selectedOrders.every(
        (order) => order.requestQuoteDatetime,
      );
      const everyOrdersHasRequestContractDatetime = selectedOrders.every(
        (order) => order.requestContractDatetime,
      );
      const someOrderHasEmptyData = selectedOrders.some(validateCommonOrderField);
      const someOrderHasEmptyBusinessCenterProfile = selectedOrders.some(validateOrderBCPFields);

      return {
        isDisabledRequestQuote: everyOrdersHasRequestQuoteDatetime
          || someOrderHasEmptyData,
        isDisabledRequestContract: everyOrdersHasRequestContractDatetime
          || someOrderHasEmptyData
          || someOrderHasEmptyBusinessCenterProfile,
      };
    },
    [selectedOrders],
  );

  const onOpenRequestQuoteModal = () => {
    setIsModalOpen(true);
  };

  const onOpenRequestContractsForm = () => {
    navigate({
      pathname: paths.wirelineEngagementsRenewalSowContractForm.split('/').pop() as string,
      search: `?${createSearchParams({ orderIds })}`,
    });
  };

  const menuItems = compact([
    (isAvailableQuoteRequest && {
      label: 'Quotes',
      onClick: onOpenRequestQuoteModal,
      isDisabled: isDisabledRequestQuote,
      icon: <CheckCircleOutlineIcon className="renewal-menu-actions__item-icon" />,
    }),
    (isAvailableContractRequest && {
      label: 'Contracts',
      onClick: onOpenRequestContractsForm,
      isDisabled: isDisabledRequestContract,
      icon: <CheckCircleOutlineIcon className="sow-menu-actions__request-icon" />,
    }),
  ]);

  return (
    <GroupMenuItems
      groupLabel="Request"
      listItems={menuItems}
    />
  );
};

export default memo(RequestActionsItems);

import { createSlice } from '@reduxjs/toolkit';
import { IEngagementRenewalItem } from 'pages/Dashboard/Dashboard.models';

export interface IEngagementsRenewalOrdersState {
  count: number;
  isLoading: boolean;
  error: object | null;
  data: IEngagementRenewalItem[];
}

const initialState: IEngagementsRenewalOrdersState = {
  isLoading: false,
  count: 0,
  data: [],
  error: {},
};

const EngagementsRenewalOrdersSlice = createSlice({
  name: 'getEngagementsRenewalOrdersData',
  initialState,
  reducers: {
    getEngagementsRenewalOrdersRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    getEngagementsRenewalOrdersSuccess: (state, action) => ({
      ...state,
      data: [...action.payload],
      isLoading: false,
    }),
    getEngagementsRenewalOrdersFailure: (state, action) => ({
      ...state,
      data: [],
      isLoading: false,
      error: action.payload,
    }),
  },
});

export const {
  getEngagementsRenewalOrdersRequest,
  getEngagementsRenewalOrdersSuccess,
  getEngagementsRenewalOrdersFailure,
} = EngagementsRenewalOrdersSlice.actions;

export const getEngagementsRenewalOrdersReducer = EngagementsRenewalOrdersSlice.reducer;

export enum EngagementTypeForChart {
    COMPLETED = 'COMPLETED',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
    CANCELED = 'CANCELED',
    DECLINED = 'DECLINED',
}

interface IRequestsTypeItemValue {
    [EngagementTypeForChart.COMPLETED]?: number;
    [EngagementTypeForChart.IN_PROGRESS]?: number;
    [EngagementTypeForChart.PENDING]?: number;
    [EngagementTypeForChart.CANCELED]?: number;
    [EngagementTypeForChart.DECLINED]?: number;
}

export interface IRequestsTypeItem {
    // eslint-disable-next-line no-restricted-globals
    name: string;
    value: IRequestsTypeItemValue;
}

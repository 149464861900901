import { combineReducers } from '@reduxjs/toolkit';

import { getSalesSowReducer } from 'core/actions/Sales/GetSalesSow';
import { requestOrdersReducer } from 'core/actions/Sales/RequestOrders';
import { requestBundlerReducer } from 'core/actions/Sales/RequestBundles';
import { getSalesSowItemReducer } from 'core/actions/Sales/GetSalesSowItem';
import { getSalesSowMetaReducer } from 'core/actions/Sales/GetSalesSowMeta';
import { editSalesSowItemReducer } from 'core/actions/Sales/EditSalesSowItem';
import { getProductCategoriesReducer } from 'core/actions/Sales/GetProductCategories';
import { getSalesRenewalSowMetaReducer } from 'core/actions/Sales/GetSalesRenewalSowMeta';
import { salesSowOrderFormReducer } from 'core/actions/Sales/SalesSowOrderForm/GetSalesSowOrderForm';
import getEngagementsLisReducer from 'pages/WirelineEngagements/WirelineEngagementsMainPage/WirelineEngagementsList/GetEngagementsList/reducer';
import commentsReducer from 'pages/WirelineEngagements/WirelineEngagementsComments/reducer';
import attachmentsReducer from 'pages/WirelineEngagements/WirelineEngagementsAttachments/reducer';
import { getContractBundlesReducer } from '@EngagementsSowData/GetContractBundles';
import { getActiveNumbersReducer } from 'core/actions/Sales/GetSalesActiveNumbers';
import { getSalesActiveNumbersItemReducer } from 'core/actions/Sales/GetSalesActiveNumbersItem';
import { editSalesActiveNumbersItemReducer } from 'core/actions/Sales/EditSalesActiveNumbersItem';
import { getSalesRenewalSowReducer } from 'core/actions/Sales/GetSalesRenewalSow';
import { getSalesRenewalSowItemReducer } from 'core/actions/Sales/GetSalesRenewalSowItem';
import { editSalesRenewalSowItemReducer } from 'core/actions/Sales/EditSalesRenewalSowItem';
import { salesOrderCommentsReducer } from 'core/actions/Sales/OrderComments';
import { getTSDsReducer } from 'core/actions/Sales/GetTSDs';
import { getCarriersReducer } from 'core/actions/Sales/GetCarriers';
import { createProductsReducer } from 'core/actions/Sales/CreateProduct';
import { salesActiveNumbersMetaReducer } from 'core/actions/Sales/GetSalesActiveNumbersMeta';
import { getScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/GetScopeDescription';
import { createScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/CreateScopeDescription';
import { editScopeDescriptionReducer } from 'pages/WirelineEngagements/WirelineEngagementsCreate/EngagementFormsWrapper/ScopeDescription/actions/EditScopeDescription';
import { declineOrderReducer } from 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal/DeclineOrderModalForm/DeclineOrder';
import { cancelOrderReducer } from 'pages/WirelineEngagements/shared/CancelOrderModalForm/CancelOrder';
import { escalationSupportReducer } from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/EscalationSupport';

const wirelineEngagementsReducer = combineReducers({
  list: getEngagementsLisReducer,
  salesSow: getSalesSowReducer,
  salesSowItem: getSalesSowItemReducer,
  editSalesSowItem: editSalesSowItemReducer,
  salesSowOrderForm: salesSowOrderFormReducer,
  requestOrders: requestOrdersReducer,
  requestBundler: requestBundlerReducer,
  salesSowMeta: getSalesSowMetaReducer,
  comments: commentsReducer,
  orderComments: salesOrderCommentsReducer,
  attachments: attachmentsReducer,
  contractBundles: getContractBundlesReducer,
  activeNumbers: getActiveNumbersReducer,
  salesActiveNumbersItem: getSalesActiveNumbersItemReducer,
  editSalesActiveNumbersItem: editSalesActiveNumbersItemReducer,
  renewalSow: getSalesRenewalSowReducer,
  renewalSowItem: getSalesRenewalSowItemReducer,
  editRenewalSowItem: editSalesRenewalSowItemReducer,
  productCategories: getProductCategoriesReducer,
  TSDs: getTSDsReducer,
  carriers: getCarriersReducer,
  createProducts: createProductsReducer,
  salesActiveNumbersMeta: salesActiveNumbersMetaReducer,
  scopeDescription: getScopeDescriptionReducer,
  editScopeDescription: editScopeDescriptionReducer,
  createScopeDescription: createScopeDescriptionReducer,
  renewalSowMeta: getSalesRenewalSowMetaReducer,
  cancelOrder: cancelOrderReducer,
  declineOrder: declineOrderReducer,
  escalationSupport: escalationSupportReducer,
});

export default wirelineEngagementsReducer;

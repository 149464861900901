import React, { useEffect } from 'react';
import Table from 'shared/Table';
import isEqual from 'lodash/isEqual';
import { useAppSelector } from 'core/store';
import { TRow } from 'shared/Table/Table.models';
import ContentShrink from 'shared/ContentShrink';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import RequestsActions from 'pages/ContractManagment/ContractManagementList/RequestsActions';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import ContractManagementRowActions from 'pages/ContractManagment/ContractManagementList/ContractManagementRowActions';
import ContractManagementTableModals from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/ContractManagementTableModals';
import {
  colDefOptionSelect,
  colDefOptionActionsTwoIcons,
  colDefOptionActionsSingleIcon,
} from 'shared/Table/constants';
import { ExcludedEngagementTypes } from 'pages/ContractManagment/constants';
import usePrevious from 'core/hooks/usePrevious';

const ContractManagementTable = () => {
  const { activeRowMode } = useTableActiveRowContext();
  const {
    isEditMode, isViewMode, isDataMode, isCommentMode,
  } = getTableRowModeBoolean(activeRowMode);

  const { rowSelection, setRowSelection } = useTableContext();
  const previousRowSelection = usePrevious(rowSelection);

  const listData = useAppSelector((state) => state.contractManagement.list.results);
  const dataCount = useAppSelector((state) => state.contractManagement.list.count);
  const isLoading = useAppSelector((state) => state.contractManagement.list.isLoading);

  const {
    isAvailableSomeRowAction,
    isAvailableTwoRowActions,
    isAvailableRequestsActions,
  } = useContractManagementPermissions();

  const isShrinked = isEditMode || isViewMode || isDataMode || isCommentMode;

  const actionsButton = isAvailableRequestsActions ? <RequestsActions /> : undefined;

  useEffect(() => {
    const newRowSelection = { ...rowSelection };
    const selectedIds = Object.keys(rowSelection).map(Number);
    const previousSelectedIds = Object.keys(previousRowSelection || {}).map(Number);

    const selectedBundles = new Set(
      listData
        .filter((item) => selectedIds.includes(item.id))
        .map((item) => item.bundle),
    );

    const addedRows = selectedIds.filter((id) => !previousSelectedIds.includes(id));
    const removedRows = previousSelectedIds.filter((id) => !selectedIds.includes(id));

    if (addedRows.length > 0) {
      listData.forEach((item) => {
        if (
          selectedBundles.has(item.bundle)
          && !ExcludedEngagementTypes.includes(item.engagementType)
        ) {
          newRowSelection[item.id] = true;
        }
      });
    }

    if (removedRows.length > 0) {
      removedRows.forEach((removedId) => {
        const bundleToRemove = listData.find((item) => item.id === removedId)?.bundle;
        if (bundleToRemove) {
          listData.forEach((row) => {
            if (row.bundle === bundleToRemove) {
              delete newRowSelection[row.id];
            }
          });
        }
      });
    }

    if (!isEqual(rowSelection, newRowSelection)) {
      setRowSelection(newRowSelection);
    }
  }, [rowSelection, listData, setRowSelection, previousRowSelection]);

  return (
    <ContentShrink
      isShrinked={isShrinked}
    >
      <Table
        showGlobalFilter
        showColumnFilters
        rowCount={dataCount}
        isLoading={isLoading}
        actionsButton={actionsButton}
        rows={listData as unknown as TRow[]}
        renderRowActions={({ row: { original } }) => (
          <ContractManagementRowActions
            row={original as unknown as IContract}
          />
        )}
        displayColumnDefOptions={{
          ...(isAvailableRequestsActions && colDefOptionSelect),
          ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
          ...(isAvailableTwoRowActions && colDefOptionActionsTwoIcons),
        }}
      />
      <ContractManagementTableModals />
    </ContentShrink>
  );
};

export default ContractManagementTable;

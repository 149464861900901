import {
  // eslint-disable-next-line no-unused-vars
  ITimelineToCompletionItem,
  TimelineToCompletionChart,
} from '@dashboardWirelineContractSpecialistDepartment/GetTimelineToCompletionTypeChart/constants';

export const mockTimelineToCompletionStatusData: ITimelineToCompletionItem[] = [
  {
    name: 'Assignment',
    value: {
      [TimelineToCompletionChart.WITHIN_TIMEFRAME]: 1,
    },
  },
  {
    name: 'Simple',
    value: {
      [TimelineToCompletionChart.WITHIN_TIMEFRAME]: 9,
      [TimelineToCompletionChart.OUTSIDE_TIMEFRAME]: 4,
    },
  },
  {
    name: 'Complex',
    value: {
      [TimelineToCompletionChart.OUTSIDE_TIMEFRAME]: 1,
    },
  },
];

import React from 'react';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
// eslint-disable-next-line no-unused-vars
import { IEngagementRenewalItem } from 'pages/Dashboard/Dashboard.models';
import { colorsOfRenewalChart } from '@dashboardWirelineDepartments/shared/constants';
import {
  engagementsRenewalOrdersMapper,
} from '@dashboardWirelineDepartments/shared/EngagementsRenewalsChart/constants';

interface IEngagementsRenewalsChart {
    isLoading: boolean;
    list: IEngagementRenewalItem[];
}

const EngagementsRenewalsChart: React.FC<IEngagementsRenewalsChart> = ({ list, isLoading }) => {
  const chartData = list.map((item) => ({
    ...item,
    name: engagementsRenewalOrdersMapper[item.name],
  }));

  return (
    <DashboardPieChart
      title="Renewal, $"
      innerRadius={60}
      paddingAngle={5}
      data={chartData}
      isLoading={isLoading}
      colors={colorsOfRenewalChart}
      className="engagements-totals"
      showDecimals
    />
  );
};

export default EngagementsRenewalsChart;

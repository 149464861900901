import React, { useEffect } from 'react';
import map from 'lodash/map';

import { unitUSD } from 'core/constants';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { periodMappingExcludeCurrent } from 'shared/PeriodAutocomplete/constants';
import { getMonthName } from 'pages/Dashboard/shared/utilities';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
// eslint-disable-next-line no-unused-vars
import { TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import { colorsOfTotalMrcChartFields, totalMrcChartFields } from '@dashboardWirelineDepartments/shared/constants';
// eslint-disable-next-line no-unused-vars
import { IEngagementsOrdersByPeriodItem, IEngagementsOrdersByPeriodItemChart } from 'pages/Dashboard/Dashboard.models';

interface IEngagementsMrcChartProps {
  isLoading: boolean;
  onPeriodChange: TSetPeriod;
  list: IEngagementsOrdersByPeriodItem[],
}

const EngagementsMrcChart: React.FC<IEngagementsMrcChartProps> = ({
  list,
  isLoading,
  onPeriodChange,
}) => {
  const data = map(list, (item) => ({
    ...item,
    xDateKey: getMonthName(item?.month),
  }));

  useEffect(() => {
    const defaultPeriod = calculatePeriod(
      periodMappingExcludeCurrent, PERIOD_SELECT.THREE_MONTHS, false,
    );
    onPeriodChange(defaultPeriod);
  }, []);

  return (
    <DashboardBarChart<IEngagementsOrdersByPeriodItemChart>
      data={data}
      colors={colorsOfTotalMrcChartFields}
      fields={totalMrcChartFields}
      title={`Order Released MRC, ${unitUSD}`}
      unit={unitUSD}
      isLoading={isLoading}
      xAxisLabel="MON"
      onPeriodChange={onPeriodChange}
      showPeriod
      isCurrentMonthIncluded={false}
    />
  );
};

export default EngagementsMrcChart;

import { ENGAGEMENT_BILING_TYPE } from '@EngagementsSowData/sowData.model';
import { contractSuportValues } from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';
import { BoolEnum } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
// eslint-disable-next-line no-unused-vars
import { IControlledFieldsConfigItem } from 'pages/WirelineEngagements/shared/FormikFieldSelector/models';

export const controlledFieldsConfigs: IControlledFieldsConfigItem = {
  creditRequest: [{
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.CREDIT_REQUEST],
  }],
  debitRequest: [{
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.DEBIT_REQUEST],
  }],
  etf: [{
    switchFieldName: 'engagementBillingType',
    switchEnabledValues: [ENGAGEMENT_BILING_TYPE.EARLY_TERM_FREE],
  }],
  existingMrc: [{
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  }, {
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  }],
  existingNrc: [{
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  }, {
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  }],
  existingServiceAccountNumber: [{
    switchFieldName: 'existingService',
    switchEnabledValues: [BoolEnum.YES],
  }, {
    switchFieldName: 'contractOrderSupport',
    switchEnabledValues: [contractSuportValues.renewal],
  }],
};

export const includedFields = ['product'];

export const additianalOrderFields = ['existingService', 'contractOrderSupport'];

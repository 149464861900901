import React from 'react';
import OrderModalItem from 'shared/OrderModalItem';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import {
  RequestTypeLabels,
} from 'pages/ContractManagment/constants';
import { requestCompletedCannotBeCompletedTooltip } from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';

import './RequestAcceptedItems.scss';

interface IRequestAcceptedItemsProps {
  selectedContracts: IContract[];
  isRequestCompleted?: boolean | undefined;
}

const RequestAcceptedItems: React.FC<IRequestAcceptedItemsProps> = ({
  selectedContracts,
  isRequestCompleted,
}) => (
  <div className="request-accepted-items">
    {selectedContracts.map(({
      requestType,
      product,
      id,
      canBeCompleted,
    }) => (
      <OrderModalItem
        key={id}
        location={RequestTypeLabels[requestType] || ''}
        product={product}
        className={isRequestCompleted && !canBeCompleted ? 'cannot-be-completed' : ''}
        tooltipTitle={isRequestCompleted && !canBeCompleted ? requestCompletedCannotBeCompletedTooltip : ''}
        withTooltip={isRequestCompleted && !canBeCompleted}
      />
    ))}
  </div>
);

RequestAcceptedItems.defaultProps = {
  isRequestCompleted: false,
};

export default RequestAcceptedItems;

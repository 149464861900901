import React from 'react';
import cx from 'classnames';

import sendIcon from 'assets/widgetImages/send.svg';

interface IChatInputProps {
  value: string
  onSend: () => void
  className?: string | undefined
  onChange: (value: string) => void
}

const ChatInput: React.FC<IChatInputProps> = ({
  value,
  onSend,
  onChange,
  className,
}) => {
  const isDisabledSend = !value.trim();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!value.trim()) return;

    if (e.key === 'Enter') {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <div className={cx('cw-chat-input', className)}>
      <input
        type="text"
        value={value}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        placeholder="Write a message"
      />
      <button
        type="button"
        onClick={onSend}
        disabled={isDisabledSend}
      >
        <img src={sendIcon} alt="send" />
      </button>
    </div>
  );
};

ChatInput.defaultProps = {
  className: '',
};

export default ChatInput;

import React, { useState, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { validateEngagementAccepted } from '@EngagementsRenewalSowData/RenewalSowOrdersActions/utilities';
import { engagementAcceptedTitle } from '@EngagementsRenewalSowData/RenewalSowOrdersActions/EngagementAccepted/constants';
import EngagementAcceptedModal from '@EngagementsRenewalSowData/RenewalSowOrdersActions/EngagementAccepted/EngagementAcceptedModal';

interface IEngagementAcceptedButtonProps {
  selectedOrders: IRenewalSowDataItem[]
}

const EngagementAcceptedButton: React.FC<IEngagementAcceptedButtonProps> = ({ selectedOrders }) => {
  const [isEngagementAcceptedModalOpen, setIsEngagementAcceptedModalOpen] = useState(false);

  const isDisabled = useMemo(
    () => isEmpty(selectedOrders) || selectedOrders?.some(validateEngagementAccepted),
    [selectedOrders]);

  const onOpenEngagementAcceptedModal = () => {
    setIsEngagementAcceptedModalOpen(true);
  };

  return (
    <>
      <Button
        disabled={isDisabled}
        startIcon={<CheckCircleOutlineIcon />}
        onClick={onOpenEngagementAcceptedModal}
        data-testid="engagement-accepted-button-test-id"
      >
        {engagementAcceptedTitle}
      </Button>
      <EngagementAcceptedModal
        selectedRenewalSowData={selectedOrders}
        isModalOpen={isEngagementAcceptedModalOpen}
        setIsModalOpen={setIsEngagementAcceptedModalOpen}
      />
    </>
  );
};

export default EngagementAcceptedButton;

import React from 'react';
import { Menu } from 'react-pro-sidebar';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { menuItemStyles } from 'layouts/Sidebar/SidebarStyles';
import { useSidebarContext } from 'layouts/Sidebar/SidebarContext';
import { MenuItemStyles as IMenuItemStyles } from 'react-pro-sidebar/dist/components/Menu';
import SidebarMenuItems from 'layouts/Sidebar/SidebarMenuItems';
import { useWhiteLabel } from 'core/hooks/useWhiteLabel';

const SidebarMenu = () => {
  const { switchIsSidebarToggled } = useSidebarContext();
  const { sidebarLogo } = useWhiteLabel();

  return (
    <Menu menuItemStyles={menuItemStyles as IMenuItemStyles}>
      <div className="sidebar__header">
        <img src={sidebarLogo} alt="logo" />
        <CloseOutlinedIcon
          onClick={switchIsSidebarToggled}
          className="sidebar__close-btn"
          fontSize="medium"
        />
      </div>
      <SidebarMenuItems />
    </Menu>
  );
};

export default SidebarMenu;

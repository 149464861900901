import { EngagementTypeForChart } from '@dashboardWirelineContractSpecialistDepartment/GetRequestsTypeChart/constants';
// eslint-disable-next-line no-unused-vars
import { ITotalOrderTypeItem } from '@dashboardWirelineContractSpecialistDepartment/TotalOrderTypeChart/constants';

export const mockTotalOrderTypeData: ITotalOrderTypeItem[] = [
  {
    name: 'Simple',
    value: {
      [EngagementTypeForChart.IN_PROGRESS]: 1,
    },
  },
  {
    name: 'Complex',
    value: {
      [EngagementTypeForChart.COMPLETED]: 9,
      [EngagementTypeForChart.IN_PROGRESS]: 4,
    },
  },
  {
    name: 'Transactional',
    value: {
      [EngagementTypeForChart.IN_PROGRESS]: 1,
    },
  },
];

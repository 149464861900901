import React, { useState } from 'react';
import { useAppDispatch } from 'core/store';
import CustomModal from 'shared/CustomModal';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { requestBundlesAction } from 'core/actions/Sales/RequestBundles';
import { REQUEST_BUNDLES_TYPE } from 'core/actions/Sales/RequestBundles/constants';
import OrderItem from '@EngagementsSowData/WirelineEngagementsSowDataModals/ActionsModal/OrderItem';
import { OrderActions } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import { orderActionsTitle, requestQuoteNotifier } from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

interface IActionsModalProps {
  isModalOpen: boolean;
  orderAction: OrderActions;
  selectedOrders: ISowDataListItem[];
  setIsActionsModalOpen: (isModalOpen: boolean) => void;
}

const ActionsModal: React.FC<IActionsModalProps> = ({
  isModalOpen,
  setIsActionsModalOpen,
  orderAction,
  selectedOrders,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData, setRowSelection } = useTableContext();
  const [isLoading, setIsLoading] = useState(false);

  const onRequestQuotes = async () => {
    const orderIds = selectedOrders.reduce<number[]>(
      (value, current) => [...value, ...(!current.requestQuoteDatetime ? [current.id] : [])],
      [],
    );

    const requestParams = { orderIds, requestType: REQUEST_BUNDLES_TYPE.QUOTE };

    try {
      await dispatch(requestBundlesAction(requestParams))
        .unwrap();

      showSuccessNotifier(requestQuoteNotifier.success);
    } catch (error) {
      showErrorNotifier(requestQuoteNotifier.error, error);
    }
  };

  const orderActionsListActions = {
    [OrderActions.REQUEST_QUOTES]: onRequestQuotes,
  };

  const onClose = () => {
    setIsActionsModalOpen(false);
  };

  const onConfirm = async () => {
    setIsLoading(true);
    await orderActionsListActions[orderAction]?.();

    setRowSelection({});
    getTableData();
    onClose();
    setIsLoading(false);
  };

  const orderList = (
    <div>
      {selectedOrders.map((order) => (
        <OrderItem key={order.id} order={order} orderAction={orderAction} />
      ))}
    </div>
  );

  return (
    <CustomModal
      areButtonsVisible
      isLoading={isLoading}
      cancelButtonText="No"
      onCloseModal={onClose}
      confirmButtonText="Yes"
      isModalOpen={isModalOpen}
      onClickConfirm={onConfirm}
      title={orderActionsTitle[orderAction]}
    >
      {orderList}
    </CustomModal>
  );
};

export default ActionsModal;

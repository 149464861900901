import React, { useMemo } from 'react';
import { IContract, RequestActionTypes } from 'pages/ContractManagment/ContractManagement.models';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import RequestAcceptedModal from 'pages/ContractManagment/ContractManagementList/RequestsActions/RequestAcceptedModal';
import RequestCompleteModal from 'pages/ContractManagment/ContractManagementList/RequestsActions/RequestCompleteModal';
import ContractSpecialistModal from 'pages/ContractManagment/ContractManagementList/RequestsActions/ContractSpecialistModal';
import { ExcludedEngagementTypes } from 'pages/ContractManagment/constants';

const { ACCEPTED, COMPLETED, CONTRACT_SPECIALIST } = RequestActionTypes;

interface IRequestsActionsModalsProps {
  actionType: string;
  onCloseModal: ()=> void;
  selectedContracts: IContract[];
}

const RequestsActionsModals: React.FC<IRequestsActionsModalsProps> = ({
  actionType,
  onCloseModal,
  selectedContracts,
}) => {
  const {
    isAvailableRequestAccepted,
    isAvailableRequestCompleted,
  } = useContractManagementPermissions();

  const isOpenAcceptedModal = actionType === ACCEPTED;
  const isOpenCompleteModal = actionType === COMPLETED;
  const isOpenContractSpecialistModal = actionType === CONTRACT_SPECIALIST;

  const filteredSelectedContracts = useMemo(
    () => (selectedContracts.filter(
      (row) => !ExcludedEngagementTypes.includes(row.engagementType),
    )),
    [selectedContracts],
  );

  return (
    <>
      {
        isAvailableRequestAccepted
          && (
          <RequestAcceptedModal
            onCloseModal={onCloseModal}
            isOpen={isOpenAcceptedModal}
            selectedContracts={filteredSelectedContracts}
          />
          )
      }
      {
        isAvailableRequestCompleted
          && (
            <RequestCompleteModal
              onCloseModal={onCloseModal}
              isOpen={isOpenCompleteModal}
              selectedContracts={filteredSelectedContracts}
            />
          )
      }
      <ContractSpecialistModal
        onCloseModal={onCloseModal}
        isOpen={isOpenContractSpecialistModal}
        selectedContracts={filteredSelectedContracts}
      />
    </>
  );
};

export default RequestsActionsModals;

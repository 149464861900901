import { FLARE_CONFIGS } from './flare';
import { NETSPARK_CONFIGS } from './netspark';

export const BRAND_NAMES = {
  FLARE: 'flare',
  NETSPARK: 'netspark',
};

export const BRANDS_CONFIGS = {
  [BRAND_NAMES.FLARE]: FLARE_CONFIGS,
  [BRAND_NAMES.NETSPARK]: NETSPARK_CONFIGS,
};

import React from 'react';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';
import DataRowSidebar from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/DataRowSidebar';
import LogAndFilesSidebar from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/LogAndFilesSidebar';
import EditRowSidebarForm from 'pages/ContractManagment/ContractManagementList/ContractManagementTableModals/EditRowSidebarForm';
import EscalationSupportModal from './EscalationSupportModal';

const ContractManagementTableModals = () => {
  const {
    isAvailableEditOrder,
    isAvailableGetOrderData,
    isAvailableGetLogAndFiles,
    isAvailableGetEscalationSupport,
  } = useContractManagementPermissions();

  return (
    <>
      { isAvailableGetOrderData && <DataRowSidebar /> }
      { isAvailableEditOrder && <EditRowSidebarForm /> }
      { isAvailableGetLogAndFiles && <LogAndFilesSidebar /> }
      { isAvailableGetEscalationSupport && <EscalationSupportModal /> }
    </>
  );
};

export default ContractManagementTableModals;

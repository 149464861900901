const crm = 'crm';
const engagements = 'engagements';
const charts = 'charts';
const sales = 'sales';

const generalEngagement = 'general-engagement/';
const projectAcceptance = 'project-acceptance/';
const projectStatus = 'project-status/';

const period = 'period/';
const periodMrc = 'period-mrc/';
const revenue = 'revenue/';
const totals = 'totals/';
const renewalMrc = 'renewal-mrc/';

const billingRequestType = 'billing-request-type/';
const billingEngagementStatus = 'billing-engagement-status/';

const technicalOverlay = 'technical-overlay/';

const booking = 'booking/';
const escalationSupport = 'escalation-support';

const retention = 'retention/';
const retentionTotal = 'retention-total/';

const insideSalesEngagementType = 'inside-sales-engagement-type/';
const insideSalesUsers = 'inside-sales-users/';
const insideSalesBooking = 'inside-sales-request-booking';

const escalationSupportTotal = 'escalation-request-status/';
const escalationSupportEscalationType = 'escalation-request-type/';

const report = 'report/';

const exportData = 'export/';

const contractManagementRequest = 'contract-management-request/';
const contractManagementEngagementType = 'contract-management-engagement-type/';

export const getEngagementsTotalOrdersUrl = `${charts}/${totals}`;
export const getEngagementsRenewalOrdersUrl = `${charts}/${renewalMrc}`;

export const getEngagementsTotalOrdersTechnicalOverlayUrl = `${charts}/${totals}`; // TODO: replace url

export const getEngagementsRevenueUrl = `${charts}/${revenue}`;
export const getEngagementsRevenueTechnicalOverlayUrl = `${charts}/${revenue}`; // TODO: replace url

export const getEngagementsOrdersMrcUrl = `${charts}/${periodMrc}`;
export const getEngagementsOrdersMrcTechnicalOverlayUrl = `${charts}/${periodMrc}`; // TODO: replace url

export const getEngagementsOrdersByPeriodsUrl = `${charts}/${period}`;
export const getEngagementsOrdersByPeriodsTechnicalOverlayUrl = `${charts}/${period}`; // TODO: replace url

export const getProjectStatusUrl = `${crm}/${engagements}/${charts}/${projectStatus}`;

export const getProjectAcceptanceUrl = `${crm}/${engagements}/${charts}/${projectAcceptance}`;

export const getGeneralEngagementUrl = `${crm}/${engagements}/${charts}/${generalEngagement}`;

export const getEngagementTypeUrl = `${charts}/${contractManagementEngagementType}`;

export const getBillingRequestTypeChartUrl = `${charts}/${billingRequestType}`;

export const getBillingEngagementStatusChartUrl = `${charts}/${billingEngagementStatus}`;

export const getTechnicalOverlayUrl = `${charts}/${technicalOverlay}`;

export const getBookingSellerDataUrl = `${sales}/${booking}${sales}/`;

export const getBookingReportDataUrl = `${sales}/${booking}${report}`;

export const getExportBookingSalesDataUrl = `${sales}/${booking}${sales}/${exportData}`;

export const getExportBookingReportDataUrl = `${sales}/${booking}${report}${exportData}`;

export const getBookingCSDataUrl = `${sales}/${booking}${contractManagementRequest}`;

export const getEscalationSupportUrl = `${crm}/${engagements}/${escalationSupport}/`;

export const getRetentionDataUrl = `${charts}/${retention}`;

export const getRetentionTotalDataUrl = `${charts}/${retentionTotal}`;

export const getRequestStatusUrl = `${charts}/${insideSalesEngagementType}`;

export const getInsideSalesUsersUrl = `${charts}/${insideSalesUsers}`;

export const insideSalesBookingUrl = `${crm}/${insideSalesBooking}/`;

export const getEscalationSupportTotalUrl = `${charts}/${escalationSupportTotal}`;

export const getEscalationSupportEscalationTypeUrl = `${charts}/${escalationSupportEscalationType}`;

import React, {
  createContext, useContext, useEffect, useMemo, useState,
} from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import periodMapping from 'shared/PeriodAutocomplete/constants';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import calculatePeriod from 'core/utilities/dateUtilities/calculatePeriod';
import { notifierMessage } from '@dashboardWirelineDepartments/shared/constants';
import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';
import { ACTIONS_DASHBOARD_WIRELINE_TECHNICAL_OVERLAY_PATHS } from 'core/models/actionsPermissions';
import { TPeriod, TSetPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { getEngagementsTORevenueAction } from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/actions/GetEngagementsTORevenue';
import { getEngagementsOrdersTOTotalAction } from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/actions/GetEngagementsOrdersTOTotal';
import useNotifiers from 'shared/Notifier/UseNotifiers';

const {
  MRC_CHART_GET,
  TOTAL_CHART_GET,
  BY_PERIOD_CHART_GET,
  BY_REVENUE_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_TECHNICAL_OVERLAY_PATHS;

const actionsPaths = {
  isAvailableGetMRCChart: MRC_CHART_GET,
  isAvailableGetTotalChart: TOTAL_CHART_GET,
  isAvailableGetByPeriodChart: BY_PERIOD_CHART_GET,
  isAvailableGetByRevenueChart: BY_REVENUE_CHART_GET,
};

interface IActionsPermissions {
  isAvailableGetMRCChart: boolean;
  isAvailableGetTotalChart: boolean;
  isAvailableGetByPeriodChart: boolean;
  isAvailableGetByRevenueChart: boolean;
}

interface ITechnicalOverlayDepartmentContext {
  getDashboardData: TSetPeriod;
  period: TPeriod;
  actionsPermissions: IActionsPermissions;
}

interface ITechnicalOverlayDepartmentProviderProps {
  children: React.ReactNode;
}

const TechnicalOverlayDepartmentContext = createContext<
ITechnicalOverlayDepartmentContext
>({} as ITechnicalOverlayDepartmentContext);

let engagementsTotalsController = HTTPService.getController();
let engagementsMrcController = HTTPService.getController();
const engagementsRevenueController = HTTPService.getController();

const TechnicalOverlayDepartmentProvider: React.FC<ITechnicalOverlayDepartmentProviderProps> = ({
  children,
}) => {
  const dispatch = useAppDispatch();

  const { showErrorNotifier } = useNotifiers();

  const actionsPermissions = useActionsPermissions<IActionsPermissions>(actionsPaths);

  const {
    isAvailableGetTotalChart,
    isAvailableGetByRevenueChart,
  } = actionsPermissions;

  const defaultPeriod = calculatePeriod(periodMapping, PERIOD_SELECT.ONE_MONTH);
  const [period, setPeriod] = useState(defaultPeriod);

  const cancelGetRevenueRequest = () => {
    HTTPService.cancelRequest(engagementsRevenueController);
  };

  const cancelGetEngagementTotalsRequest = () => {
    HTTPService.cancelRequest(engagementsTotalsController);
  };

  const getEngagementsRenevue = async (newPeriod) => {
    if (!isAvailableGetByRevenueChart) return;
    cancelGetRevenueRequest();

    try {
      engagementsMrcController = HTTPService.getController();
      await dispatch(getEngagementsTORevenueAction(
        { controller: engagementsMrcController, params: newPeriod },
      )).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsRevenue.error, e);
    }
  };

  const getEngagementsTotals = async (newPeriod) => {
    if (!isAvailableGetTotalChart) return;
    cancelGetEngagementTotalsRequest();

    try {
      engagementsTotalsController = HTTPService.getController();

      await dispatch(getEngagementsOrdersTOTotalAction(
        { controller: engagementsTotalsController, params: newPeriod },
      )).unwrap();
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsTotals.error, e);
    }
  };

  const getDashboardData = (newPeriod: TPeriod) => {
    getEngagementsTotals(newPeriod);
    getEngagementsRenevue(newPeriod);
    setPeriod(newPeriod);
  };

  const cancelRequests = () => {
    cancelGetEngagementTotalsRequest();
    cancelGetRevenueRequest();
  };

  useEffect(() => {
    getDashboardData(period);

    return () => cancelRequests();
  }, []);

  const value = useMemo(() => ({
    getDashboardData,
    period,
    actionsPermissions,
  }), [period]);

  return (
    <TechnicalOverlayDepartmentContext.Provider value={value}>
      {children}
    </TechnicalOverlayDepartmentContext.Provider>
  );
};

export const useTechnicalOverlayDepartmentContext = () => useContext(
  TechnicalOverlayDepartmentContext,
);

export default TechnicalOverlayDepartmentProvider;

import React from 'react';
import MoneyCell from 'shared/Table/shared/MoneyCell';
import {
  // eslint-disable-next-line no-unused-vars
  ORDER_TYPE, REQUEST_TYPE, CREDIT_BUCKET, Status, PortDescription,
  System, ManagementOptions, CurrentCarrier, InternetServiceType,
  ClassOfServiceType, HandoffType, Carrier, WhosLogin, Term, PortSpeed, Cir,
  Jurisdiction, EvcType, OneTypeInstallationOptions, CosProfileAseod,
  NumberOfIpAddresses, NewTfNumbers, RespOrg, CopyBillForResporgProvided,
  IPAddressType, PbxInterfaceType, StateEnum, AsnType, CerType, PerType,
  CreditRequest, CustomerSupport, EtfEnum, GbqEnum, SalesSupport,
} from '@EngagementsSowData/sowData.model';
import { BoolEnum, ROLE_STATUS, COMMISSION_TYPE } from 'pages/WirelineEngagements/WirelineEngagementsTables/wirelineEngagementsTables.model';
import { requestOrderPath, contractFormPath } from 'core/routing/paths';
// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import { MRT_ColumnDef as ColumnDef } from 'material-react-table';
import { TRow } from 'shared/Table/Table.models';
import RequestProvidedDate from 'shared/Table/shared/RequestProvidedDate';
import SowDataTableDateColumnItem from '@EngagementsSowData/SowDataTableDateColumnItem';
import {
  boolLabels,
  statusLabels,
  roleStatusLabels,
  requestStatusLabels,
  commissionTypeLabels,
  portDescriptionLabels,
  providedBodyCellStyle,
  providedHeadCellStyle,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';
import EscalationSupportView from 'pages/WirelineEngagements/shared/EscalationSupportView';
import {
  billingSupportLabels,
  billingSupportValues,
  contractSuportValues,
  sellerRequestTypeValues,
  sellerRequestTypeLabels,
  contractOrderSupportLabels,
  projectManagerSupportValues,
  projectManagementSupportLabels,
} from 'pages/WirelineEngagements/shared/AddOrderModalForm/constants';

export const orderTypeLabels = {
  [ORDER_TYPE.NEW]: 'New',
  [ORDER_TYPE.CANCELED]: 'Canceled',
  [ORDER_TYPE.ADMIN_SUPPORT]: 'Admin support',
  [ORDER_TYPE.BILLING]: 'Billing',
  [ORDER_TYPE.DISCONNECT]: 'Disconnect',
  [ORDER_TYPE.ESCALATION]: 'Escalation',
  [ORDER_TYPE.FUTURE_ORDER]: 'Future Order',
  [ORDER_TYPE.GENERAL_QUESTIONS]: 'General Questions',
  [ORDER_TYPE.MACD]: 'MACD',
  [ORDER_TYPE.MOVE_BVOIP]: 'Move - BVOIP',
  [ORDER_TYPE.OUTAGE_MAINTENANCE]: 'Outage/Maintenance',
  [ORDER_TYPE.PORT_BVOIP]: 'Port - BVOIP',
  [ORDER_TYPE.IN_PROGRESS]: 'In Progress',
  [ORDER_TYPE.COMPLETED]: 'Completed',
  [ORDER_TYPE.RE_ORDER]: 'Re-Order',
};

export const orderTypeOptions: IOption[] = [
  {
    value: ORDER_TYPE.NEW,
    label: orderTypeLabels[ORDER_TYPE.NEW],
  },
  {
    value: ORDER_TYPE.CANCELED,
    label: orderTypeLabels[ORDER_TYPE.CANCELED],
  },
  {
    value: ORDER_TYPE.ADMIN_SUPPORT,
    label: orderTypeLabels[ORDER_TYPE.ADMIN_SUPPORT],
  },
  {
    value: ORDER_TYPE.BILLING,
    label: orderTypeLabels[ORDER_TYPE.BILLING],
  },
  {
    value: ORDER_TYPE.DISCONNECT,
    label: orderTypeLabels[ORDER_TYPE.DISCONNECT],
  },
  {
    value: ORDER_TYPE.ESCALATION,
    label: orderTypeLabels[ORDER_TYPE.ESCALATION],
  },
  {
    value: ORDER_TYPE.FUTURE_ORDER,
    label: orderTypeLabels[ORDER_TYPE.FUTURE_ORDER],
  },
  {
    value: ORDER_TYPE.GENERAL_QUESTIONS,
    label: orderTypeLabels[ORDER_TYPE.GENERAL_QUESTIONS],
  },
  {
    value: ORDER_TYPE.MACD,
    label: orderTypeLabels[ORDER_TYPE.MACD],
  },
  {
    value: ORDER_TYPE.MOVE_BVOIP,
    label: orderTypeLabels[ORDER_TYPE.MOVE_BVOIP],
  },
  {
    value: ORDER_TYPE.OUTAGE_MAINTENANCE,
    label: orderTypeLabels[ORDER_TYPE.OUTAGE_MAINTENANCE],
  },
  {
    value: ORDER_TYPE.PORT_BVOIP,
    label: orderTypeLabels[ORDER_TYPE.PORT_BVOIP],
  },
  {
    value: ORDER_TYPE.IN_PROGRESS,
    label: orderTypeLabels[ORDER_TYPE.IN_PROGRESS],
  },
  {
    value: ORDER_TYPE.COMPLETED,
    label: orderTypeLabels[ORDER_TYPE.COMPLETED],
  },
  {
    value: ORDER_TYPE.RE_ORDER,
    label: orderTypeLabels[ORDER_TYPE.RE_ORDER],
  },
];

export const requestTypeLabels = {
  [REQUEST_TYPE.NOT_SELECTED]: 'Not selected',
  [REQUEST_TYPE.CREDIT_REQUEST]: 'Credit Request',
  [REQUEST_TYPE.CUSTOMER_SUPPORT]: 'Customer Support',
  [REQUEST_TYPE.EARLY_TERM_FREE]: 'Early Term Free',
  [REQUEST_TYPE.GENERAL_BILLING_QUESTION]: 'General Billing Question',
  [REQUEST_TYPE.SALES_SUPPORT]: 'Sales Support',
  [REQUEST_TYPE.OTHER]: 'Other',
};

export const requestTypeOptions: IOption[] = [
  {
    value: REQUEST_TYPE.NOT_SELECTED,
    label: requestTypeLabels[REQUEST_TYPE.NOT_SELECTED],
  },
  {
    value: REQUEST_TYPE.CREDIT_REQUEST,
    label: requestTypeLabels[REQUEST_TYPE.CREDIT_REQUEST],
  },
  {
    value: REQUEST_TYPE.CUSTOMER_SUPPORT,
    label: requestTypeLabels[REQUEST_TYPE.CUSTOMER_SUPPORT],
  },
  {
    value: REQUEST_TYPE.EARLY_TERM_FREE,
    label: requestTypeLabels[REQUEST_TYPE.EARLY_TERM_FREE],
  },
  {
    value: REQUEST_TYPE.GENERAL_BILLING_QUESTION,
    label: requestTypeLabels[REQUEST_TYPE.GENERAL_BILLING_QUESTION],
  },
  {
    value: REQUEST_TYPE.SALES_SUPPORT,
    label: requestTypeLabels[REQUEST_TYPE.SALES_SUPPORT],
  },
  {
    value: REQUEST_TYPE.OTHER,
    label: requestTypeLabels[REQUEST_TYPE.OTHER],
  },
];

export const creditBucketLabels = {
  [CREDIT_BUCKET.NOT_SELECTED]: 'Not selected',
  [CREDIT_BUCKET.EFF]: 'EFF',
  [CREDIT_BUCKET.CC]: 'CC',
  [CREDIT_BUCKET.PARTIAL]: 'Partial',
};

export const creditBucketOptions: IOption[] = [
  {
    value: CREDIT_BUCKET.NOT_SELECTED,
    label: creditBucketLabels[CREDIT_BUCKET.NOT_SELECTED],
  },
  {
    value: CREDIT_BUCKET.EFF,
    label: creditBucketLabels[CREDIT_BUCKET.EFF],
  },
  {
    value: CREDIT_BUCKET.CC,
    label: creditBucketLabels[CREDIT_BUCKET.CC],
  },
  {
    value: CREDIT_BUCKET.PARTIAL,
    label: creditBucketLabels[CREDIT_BUCKET.PARTIAL],
  },
];

export const statusOptions: IOption[] = [
  {
    value: Status.NOT_SELECTED,
    label: statusLabels[Status.NOT_SELECTED],
  },
  {
    value: Status.FUTURE,
    label: statusLabels[Status.FUTURE],
  },
  {
    value: Status.IN_PROGRESS,
    label: statusLabels[Status.IN_PROGRESS],
  },
  {
    value: Status.COMPLETED,
    label: statusLabels[Status.COMPLETED],
  },
];

export const systemLabels = {
  [System.ASAP]: 'ASAP',
  [System.BOOST]: 'BOOST',
  [System.NON_AT_T]: 'Non-AT&T',
  [System.NI_EXPRESS]: 'NI Express',
  [System.OPUS]: 'OPUS',
  [System.RDS]: 'RDS',
  [System.SALES_EXPRESS]: 'Sales Express',
  [System.USELL]: 'USELL',
};

export const managementOptionsLabels = {
  [ManagementOptions.AT_T_MANAGED_ROUTER]: 'AT&T Managed Router',
  [ManagementOptions.CUSTOMER_MANAGED_ROUTER]: 'Customer Managed Router',
};

export const currentCarrierLabels = {
  [CurrentCarrier.AT_T]: 'AT&T',
  [CurrentCarrier.CABLE]: 'Cable',
  [CurrentCarrier.CLEC_HOSTED_VOIP]: 'CLEC/Hosted VOIP',
  [CurrentCarrier.VERIZON]: 'Verizon',
};

export const internetServiceTypeLabels = {
  [InternetServiceType.CABLE]: 'Cable',
  [InternetServiceType.DSL]: 'DSL',
  [InternetServiceType.ETHERNET]: 'Ethernet',
  [InternetServiceType.T1]: 'T1',
};

export const classOfServiceTypeLabels = {
  [ClassOfServiceType.NON_CRITICAL_HIGH]: 'Non Critical High',
  [ClassOfServiceType.BUSINESS_CRITICAL_MEDIUM]: 'Business Critical Medium',
  [ClassOfServiceType.BUSINESS_CRITICAL_HIGH]: 'Business Critical High',
  [ClassOfServiceType.INTERACTIVE]: 'Interactive',
  [ClassOfServiceType.REAL_TIME]: 'Real Time',
};

export const handoffTypeLabels = {
  [HandoffType.ELECTRICAL_100]: '100baseTX = Electrical',
  [HandoffType.SMF_1000]: '1000BaseLX = SMF',
  [HandoffType.MMF_1000]: '1000BaseSX = MMF',
  [HandoffType.ELECTRICAL_1000]: '1000BaseTX = Electrical',
  [HandoffType.SMF_10G]: '10G = SMF',
  [HandoffType.MMF_10G]: '10G = MMF',
};

export const carrierLabels = {
  [Carrier.ATT]: 'ATT',
  [Carrier.AVANT]: 'Avant',
  [Carrier.INTELISYS]: 'Intelisys',
  [Carrier.SANDLER]: 'Sandler',
  [Carrier.TBI]: 'TBI',
  [Carrier.TCG]: 'TCG',
  [Carrier.TELARUS]: 'Telarus',
};

export const whosLoginLabels = {
  [WhosLogin.CHARLES_BARKER]: 'Charles Barker',
  [WhosLogin.LANDON_SOUTHWELL]: 'Landon Southwell',
  [WhosLogin.LATANYA_COLLIER]: 'LaTanya Collier',
  [WhosLogin.SARAH_JANE_HARRIS]: 'Sarah Jane Harris',
};

export const termLabels = {
  [Term.ZERO]: 'Month To Month',
  [Term.ONE]: '12 - One Year',
  [Term.TWO]: '24 - Two Years',
  [Term.THREE]: '36 - Three Years',
  [Term.FOUR]: '48 - Four Years',
  [Term.FIVE]: '60 - Five Years',
};

export const portSpeedLabels = {
  [PortSpeed.ONE_HUNDRED_MBPS]: '100Mbps',
  [PortSpeed.ONE_GBPS]: '1Gbps',
  [PortSpeed.TEN_GBPS]: '10Gbps',
  [PortSpeed.FORTY_GBPS]: '40Gbps',
  [PortSpeed.ONE_HUNDRED_GBPS]: '100Gbps',
};

export const cirLabels = {
  [Cir.TWO_MBPS]: '2Mbps',
  [Cir.FOUR_MBPS]: '4Mbps',
  [Cir.FIVE_MBPS]: '5Mbps',
  [Cir.EIGHT_MBPS]: '8Mbps',
  [Cir.TEN_MBPS]: '10Mbps',
  [Cir.TWENTY_MBPS]: '20Mbps',
  [Cir.FIFTY_MBPS]: '50Mbps',
  [Cir.ONE_HUNDRED_MBPS]: '100Mbps',
  [Cir.ONE_HUNDRED_FIFTY_MBPS]: '150Mbps',
  [Cir.TWO_HUNDRED_FIFTY_MBPS]: '250Mbps',
  [Cir.FOUR_HUNDRED_MBPS]: '400Mbps',
  [Cir.FIVE_HUNDRED_MBPS]: '500Mbps',
  [Cir.SIX_HUNDRED_MBPS]: '600Mbps',
  [Cir.ONE_GBPS]: '1Gbps',
  [Cir.TWO_GBPS]: '2Gbps',
  [Cir.THREE_GBPS]: '3Gbps',
  [Cir.FOUR_GBPS]: '4Gbps',
  [Cir.FIVE_GBPS]: '5Gbps',
  [Cir.SIX_GBPS]: '6Gbps',
  [Cir.SEVEN_GBPS]: '7Gbps',
  [Cir.EIGHT_GBPS]: '8Gbps',
  [Cir.NINE_GBPS]: '9Gbps',
  [Cir.TEN_GBPS]: '10Gbps',
  [Cir.TWENTY_GBPS]: '20Gbps',
  [Cir.FORTY_GBPS]: '40Gbps',
  [Cir.FIFTY_GBPS]: '50Gbps',
  [Cir.ONE_HUNDRED_GBPS]: '100Gbps',
};

export const jurisdictionLabels = {
  [Jurisdiction.INTRASTATE]: 'Intrastate',
  [Jurisdiction.INTERSTATE]: 'Interstate',
};

export const evcTypeLabels = {
  [EvcType.MULTIPOINT]: 'MultiPoint',
  [EvcType.POINT_TO_POINT]: 'Point to Point',
};

export const newTfNumbersLabels = {
  [NewTfNumbers.YES]: 'Yes',
  [NewTfNumbers.NO]: 'No',
};

export const respOrgLabels = {
  [RespOrg.YES]: 'Yes',
  [RespOrg.NO]: 'No',
};

export const copyBillResporgProvidedLabels = {
  [CopyBillForResporgProvided.YES]: 'Yes',
  [CopyBillForResporgProvided.NO]: 'No',
};

export const numberOfIpAddressesLabels = {
  [NumberOfIpAddresses.NUMBER_24]: '/24',
  [NumberOfIpAddresses.NUMBER_25]: '/25',
  [NumberOfIpAddresses.NUMBER_26]: '/26',
  [NumberOfIpAddresses.NUMBER_27]: '/27',
  [NumberOfIpAddresses.NUMBER_28]: '/28',
  [NumberOfIpAddresses.NUMBER_29]: '/29',
  [NumberOfIpAddresses.NUMBER_30]: '/30',
  [NumberOfIpAddresses.USABLE_14]: '/28 - Usable 14',
  [NumberOfIpAddresses.USABLE_6]: '/29 - Usable 6',
  [NumberOfIpAddresses.OTHER]: 'Other - Approval Required',
};

export const cosProfileAseodLabels = {
  [CosProfileAseod.NON_CRITICAL_HIGH]: 'Non Critical High',
  [CosProfileAseod.BUSINESS_CRITICAL_MEDIUM]: 'Business Critical Medium',
  [CosProfileAseod.BUSINESS_CRITICAL_HIGH]: 'Business Critical High',
  [CosProfileAseod.INTERACTIVE]: 'Interactive',
  [CosProfileAseod.REAL_TIME]: 'Real Time',
};

export const asnTypeLabels = {
  [AsnType.CABLE]: 'Cable',
  [AsnType.DSL]: 'DSL',
  [AsnType.ETHERNET]: 'Ethernet',
  [AsnType.T1]: 'T1',
};

export const cerTypeLabels = {
  [CerType.CABLE]: 'Cable',
  [CerType.DSL]: 'DSL',
  [CerType.ETHERNET]: 'Ethernet',
  [CerType.T1]: 'T1',
};

export const perTypeLabels = {
  [PerType.CABLE]: 'Cable',
  [PerType.DSL]: 'DSL',
  [PerType.ETHERNET]: 'Ethernet',
  [PerType.T1]: 'T1',
};

export const oneTypeInstallationOptionsLabels = {
  [OneTypeInstallationOptions.AT_T_INSTALL]: 'AT&T Install',
  [OneTypeInstallationOptions.CUSTOMER_INSTALL]: 'Customer Install',
};

export const ipAddressTypeLabels = {
  [IPAddressType.STATIC]: 'Static',
  [IPAddressType.DYNAMIC]: 'Dynamic',
};

export const boundConnectionRequiredLabels = {
  [BoolEnum.YES]: 'Yes - ATT Provide',
  [BoolEnum.NO]: 'No - Customer Provided',
};

export const pbxInterfaceTypeLabels = {
  [PbxInterfaceType.SIP]: 'SIP',
  [PbxInterfaceType.ANALOG]: 'Analog',
  [PbxInterfaceType.PRI]: 'PRI',
};

export const stateLabels = {
  [StateEnum.NEW]: 'New',
  [StateEnum.EXISTING]: 'Existing',
};

export const creditRequestLabels = {
  [CreditRequest.CANCELLED_ORDER]: 'Cancelled order',
  [CreditRequest.EARLY_BILLING]: 'Early billing',
  [CreditRequest.INCORRECT_RATES]: 'Incorrect Rates (Rack Rates/Not per contract/Incorrect LAC)',
  [CreditRequest.LATE_DISCONNECT]: 'Late disconnect',
  [CreditRequest.NREC]: 'NREC (Non Recurring Charge)',
  [CreditRequest.OTC]: 'OTC (One Time Charge)',
  [CreditRequest.POST_BILL_ETF]: 'Post-bill ETF',
  [CreditRequest.THRIFTY_BILLING]: 'Thrifty billing/ Advanced features (999/829)',
};

export const customerSupportLabels = {
  [CustomerSupport.BILL_REVIEW]: 'Bill Review/Explanation',
  [CustomerSupport.OTHER]: 'Other',
};

export const etfEnumLabels = {
  [EtfEnum.WAIVER]: 'Waiver (Pre-bill/Post bill)',
  [EtfEnum.QUOTE]: 'Quote (for ASE disco/as part of doc)',
};

export const gbqEnumLabels = {
  [GbqEnum.ADDRESS_CHANGE]: 'Address Change',
  [GbqEnum.BILL_SPLIT]: 'Bill Split',
  [GbqEnum.CHARGE_EXPLANATION]: 'Charge Explanation',
};

export const salesSupportLabels = {
  [SalesSupport.BILL_QUESTION]: 'Bill question',
  [SalesSupport.OTHER]: 'Other',
};

export const columns = [
  {
    accessorKey: 'requestQuoteDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'provideQuoteDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest={false} requestProvidedDate={renderedCellValue as string} />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'requestContractDatetime',
    Cell: ({ renderedCellValue, row }) => renderedCellValue && (
      <SowDataTableDateColumnItem
        isRequest
        requestProvidedDate={renderedCellValue as string}
        viewPath={`${contractFormPath}/${row.original.contractBundleId}`}
      />
    ),
  },
  {
    accessorKey: 'provideContractDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest={false} requestProvidedDate={renderedCellValue as string} />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'requestOrderActionDatetime',
    Cell: ({ renderedCellValue, row: { id } }) => renderedCellValue && (
      <SowDataTableDateColumnItem
        isRequest
        viewPath={`${requestOrderPath}/${id}`}
        requestProvidedDate={renderedCellValue as string}
      />
    ),
  },
  {
    accessorKey: 'provideOrderActionDatetime',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest={false} requestProvidedDate={renderedCellValue as string} />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'orderType',
    Cell: ({ renderedCellValue }) => (
      orderTypeLabels[renderedCellValue as ORDER_TYPE] || renderedCellValue
    ),
  },
  {
    accessorKey: 'requestType',
    Cell: ({ renderedCellValue }) => (
      requestTypeLabels[renderedCellValue as REQUEST_TYPE] || renderedCellValue
    ),
  },
  {
    accessorKey: 'portDescription',
    Cell: ({ renderedCellValue }) => (
      portDescriptionLabels[renderedCellValue as PortDescription] || renderedCellValue
    ),
  },
  {
    accessorKey: 'currentCarrier',
    Cell: ({ renderedCellValue }) => (
      currentCarrierLabels[renderedCellValue as CurrentCarrier] || renderedCellValue
    ),
  },
  {
    accessorKey: 'internetServiceType',
    Cell: ({ renderedCellValue }) => (
      internetServiceTypeLabels[renderedCellValue as InternetServiceType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'cer',
    Cell: ({ renderedCellValue }) => (
      internetServiceTypeLabels[renderedCellValue as InternetServiceType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'per',
    Cell: ({ renderedCellValue }) => (
      internetServiceTypeLabels[renderedCellValue as InternetServiceType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'asn',
    Cell: ({ renderedCellValue }) => (
      internetServiceTypeLabels[renderedCellValue as InternetServiceType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'carrier',
    Cell: ({ renderedCellValue }) => (
      carrierLabels[renderedCellValue as Carrier] || renderedCellValue
    ),
  },
  {
    accessorKey: 'portSpeed',
    Cell: ({ renderedCellValue }) => (
      portSpeedLabels[renderedCellValue as PortSpeed] || renderedCellValue
    ),
  },
  {
    accessorKey: 'cir',
    Cell: ({ renderedCellValue }) => (
      cirLabels[renderedCellValue as Cir] || renderedCellValue
    ),
  },
  {
    accessorKey: 'newTfNumbers',
    Cell: ({ renderedCellValue }) => (
      newTfNumbersLabels[renderedCellValue as NewTfNumbers] || renderedCellValue
    ),
  },
  {
    accessorKey: 'respOrg',
    Cell: ({ renderedCellValue }) => (
      respOrgLabels[renderedCellValue as RespOrg] || renderedCellValue
    ),
  },
  {
    accessorKey: 'copyBillForResporgProvided',
    Cell: ({ renderedCellValue }) => (
      copyBillResporgProvidedLabels[
        renderedCellValue as CopyBillForResporgProvided
      ] || renderedCellValue
    ),
  },
  {
    accessorKey: 'asn',
    Cell: ({ renderedCellValue }) => (
      asnTypeLabels[renderedCellValue as AsnType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'cer',
    Cell: ({ renderedCellValue }) => (
      cerTypeLabels[renderedCellValue as CerType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'per',
    Cell: ({ renderedCellValue }) => (
      perTypeLabels[renderedCellValue as PerType] || renderedCellValue
    ),
  },
  {
    accessorKey: 'isOutOfBoundConnectionRequired',
    Cell: ({ renderedCellValue }) => (
      boundConnectionRequiredLabels[renderedCellValue as BoolEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'ifCustomerHasExistingVvbxDoWeHaveProductApproval',
    Cell: ({ renderedCellValue }) => (
      boolLabels[renderedCellValue as BoolEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'migrationOfServiceStatus',
    Cell: ({ renderedCellValue }) => (
      boolLabels[renderedCellValue as BoolEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'contractSpecialistStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'projectManagementStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSpecialistStatus',
    Cell: ({ renderedCellValue }) => (
      roleStatusLabels[renderedCellValue as ROLE_STATUS] || renderedCellValue
    ),
  },
  {
    accessorKey: 'commissionType',
    Cell: ({ renderedCellValue }) => (
      commissionTypeLabels[renderedCellValue as COMMISSION_TYPE] || renderedCellValue
    ),
  },
  {
    accessorKey: 'newExisting',
    Cell: ({ renderedCellValue }) => (
      stateLabels[renderedCellValue as StateEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'tnInventoryStatus',
    Cell: ({ renderedCellValue }) => (
      stateLabels[renderedCellValue as StateEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'customerSupport',
    Cell: ({ renderedCellValue }) => (
      customerSupportLabels[renderedCellValue as CustomerSupport] || renderedCellValue
    ),
  },
  {
    accessorKey: 'gbq',
    Cell: ({ renderedCellValue }) => (
      gbqEnumLabels[renderedCellValue as GbqEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'salesSupport',
    Cell: ({ renderedCellValue }) => (
      salesSupportLabels[renderedCellValue as SalesSupport] || renderedCellValue
    ),
  },
  {
    accessorKey: 'renewableService',
    Cell: ({ renderedCellValue }) => (
      boolLabels[renderedCellValue as BoolEnum] || renderedCellValue
    ),
  },
  {
    accessorKey: 'status',
    Cell: ({ renderedCellValue }) => (
      statusLabels[renderedCellValue as Status] || renderedCellValue
    ),
  },
  {
    accessorKey: 'sellerRequestType',
    Cell: ({ renderedCellValue }) => (
      sellerRequestTypeLabels[renderedCellValue as sellerRequestTypeValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'contractOrderSupport',
    Cell: ({ renderedCellValue }) => (
      contractOrderSupportLabels[renderedCellValue as contractSuportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'projectManagementSupport',
    Cell: ({ renderedCellValue }) => (
      projectManagementSupportLabels[renderedCellValue as projectManagerSupportValues]
      || renderedCellValue
    ),
  },
  {
    accessorKey: 'billingSupport',
    Cell: ({ renderedCellValue }) => (
      billingSupportLabels[renderedCellValue as billingSupportValues] || renderedCellValue
    ),
  },
  {
    accessorKey: 'mrc',
    Cell: MoneyCell,
  },
  {
    accessorKey: 'nrc',
    Cell: MoneyCell,
  },
  {
    accessorKey: 'creditAmount',
    Cell: MoneyCell,
  },
  {
    accessorKey: 'contractEngagementAcceptedDate',
    Cell: ({ renderedCellValue }) => renderedCellValue && (
      <RequestProvidedDate isRequest requestProvidedDate={renderedCellValue as string} />
    ),
  },
  {
    accessorKey: 'hasEscalationRequests',
    Cell: ({ row: { original } }) => (
      <EscalationSupportView
        product={original.product as string}
        customerCompanyName={original.customerCompanyName as string}
        hasEscalationRequests={original.hasEscalationRequests as boolean}
      />
    ),
    muiTableHeadCellProps: providedHeadCellStyle,
    muiTableBodyCellProps: providedBodyCellStyle,
  },
  {
    accessorKey: 'requestQuoteStatus',
    Cell: ({ renderedCellValue }) => (
      requestStatusLabels[renderedCellValue as string] || renderedCellValue
    ),
  },
  {
    accessorKey: 'requestContractStatus',
    Cell: ({ renderedCellValue }) => (
      requestStatusLabels[renderedCellValue as string] || renderedCellValue
    ),
  },
  {
    accessorKey: 'requestOrderActionStatus',
    Cell: ({ renderedCellValue }) => (
      requestStatusLabels[renderedCellValue as string] || renderedCellValue
    ),
  },
] as ColumnDef<TRow>[];

export const getSowItemMessage = {
  error: 'Failed to load SOW item',
};

export const patchSalesSowItemActionMessage = {
  success: 'SOW item updated successfully',
  error: 'Failed to update SOW item',
};

export const duplicateOrderNotifier = {
  success: 'Order has been duplicated successfully',
  error: 'Failed to duplicate order',
};

export const editSowDataTitle = 'Edit SOW Data';
export const viewSowDataTitle = 'View SOW Data';

export const endpointToGetSowDataSchemaName = '/api/sales/sow/{id}/';
export const dynamicProductSchemaName = 'DynamicProductsSerializerSchema';

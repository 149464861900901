// eslint-disable-next-line no-unused-vars
import React, { FC } from 'react';
import { IChartItem } from 'core/models/Recharts.models';

import './ChartLegend.scss';

interface IChartLegend {
    data: IChartItem[];
    colors: string[];
    showDecimals?: boolean | undefined;
}

const ChartLegend :FC<IChartLegend> = ({ data, colors, showDecimals }) => (
  <div className="dashboard-pie__legend">
    <div className="dashboard-pie__legend-list">
      {data.map((item, index) => (
        <div
          className="dashboard-pie__legend-item"
          key={item.name}
          data-testid="legend-item"
        >
          <div className="dashboard-pie__legend-name">
            <i
              className="dashboard-pie__legend-icon"
              style={{ backgroundColor: colors[index % colors.length] }}
            />
            {item.name}
          </div>
          <div className="dashboard-pie__legend-value">
            {
              showDecimals ? Number(item.value).toFixed(2) : item.value
            }
          </div>
        </div>
      ))}
    </div>
  </div>
);

ChartLegend.defaultProps = {
  showDecimals: false,
};

export default ChartLegend;

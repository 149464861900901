import React from 'react';
import {
  Sidebar as ProSidebar,
} from 'react-pro-sidebar';

import styles from 'assets/styles/_resources.scss';
import { useIsBrand } from 'core/hooks/useIsBrand';
import { BRAND_NAMES } from 'core/whiteLabel/whiteLabel';
import ChatWidgetWrapper from 'layouts/Sidebar/ChatWidgetWrapper';
import { useSidebarContext } from './SidebarContext';
import SidebarMenu from './SidebarMenu';

import './Sidebar.scss';

const Sidebar = () => {
  const { isCollapsed, isToggled, switchIsSidebarToggled } = useSidebarContext();
  const isFlareBrand = useIsBrand(BRAND_NAMES.FLARE);

  return (
    <ProSidebar
      backgroundColor={styles.sidebarColor}
      toggled={isToggled}
      collapsed={isCollapsed}
      breakPoint="xl"
      className="sidebar"
      width="250px"
      collapsedWidth="50px"
      data-testid="sidebar"
      onBackdropClick={switchIsSidebarToggled}
    >
      <div className="sidebar__content">
        <SidebarMenu />
        {isFlareBrand && <ChatWidgetWrapper />}
      </div>
    </ProSidebar>
  );
};

export default Sidebar;

import React from 'react';
import { useAppSelector } from 'core/store';
import EngagementsRenewalsChart
  from '@dashboardWirelineDepartments/shared/EngagementsRenewalsChart/EngagementsRenewalsChart';

const SellerRenewalChart = () => {
  const engagementsRenewalOrders = useAppSelector(
    (state) => state.dashboard.engagementsRenewals.data,
  );
  const isLoading = useAppSelector((state) => state.dashboard.engagementsRenewals.isLoading);

  return (
    <EngagementsRenewalsChart
      isLoading={isLoading}
      list={engagementsRenewalOrders}
    />
  );
};

export default SellerRenewalChart;

import React, { useEffect, useMemo } from 'react';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import { useTableContext } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';
import { requestAcceptedAction } from 'core/actions/ContractManagement/RequestAccepted';
import { clearContractManagementListByBundle } from 'pages/ContractManagment/ContractManagementList/GetContractManagementList/';
import RequestActionModalContent from 'pages/ContractManagment/ContractManagementList/RequestsActions/shared/RequestActionModalContent';
import { getContractManagementListByBundleAction } from 'pages/ContractManagment/ContractManagementList/GetContractManagementList/actions';
import {
  requestAcceptedNotifier,
  requestAcceptedModalTitle,
} from 'pages/ContractManagment/ContractManagementList/RequestsActions/constants';
import { ExcludedEngagementTypes } from 'pages/ContractManagment/constants';

interface IRequestAcceptedModalProps {
  onCloseModal: ()=> void;
  selectedContracts: IContract[];
}

const CONTROLLER = HTTPService.getController();

const RequestAcceptedModal: React.FC<IRequestAcceptedModalProps> = ({
  selectedContracts, onCloseModal,
}) => {
  const dispatch = useAppDispatch();
  const { showSuccessNotifier, showErrorNotifier } = useNotifiers();
  const { getTableData } = useTableContext();
  const isLoadingContractsByBundle = useAppSelector(
    (state) => state.contractManagement.list.isLoading,
  );
  const contractsByBundle = useAppSelector(
    (state) => state.contractManagement.list.resultsByBundle,
  );
  const isLoadingAcceptedContract = useAppSelector(
    (state) => state.contractManagement.requestAccepted.isLoading,
  );

  const isLoading = isLoadingContractsByBundle || isLoadingAcceptedContract;

  const getBundleIds = () => Array.from(new Set(selectedContracts.map((item) => item.bundle)));

  const onRequestAccepted = async () => {
    const bundleIds = getBundleIds();
    await Promise.all(bundleIds.map(
      async (bundleId) => {
        try {
          const params = {
            bundleId,
          };
          await dispatch(requestAcceptedAction(params)).unwrap();

          showSuccessNotifier(requestAcceptedNotifier.success);
          getTableData();
          onCloseModal();
        } catch (e) {
          showErrorNotifier(requestAcceptedNotifier.error, e);
        }
      }),
    );
  };

  useEffect(() => {
    if (selectedContracts.length) {
      const bundleIds = getBundleIds();
      const bundlesStr = `?requestBundleId=${bundleIds.join()}`;
      dispatch(getContractManagementListByBundleAction(CONTROLLER, bundlesStr));
    }
  }, [selectedContracts]);

  useEffect(() => () => {
    dispatch(clearContractManagementListByBundle());
  }, []);

  const filteredContracts = useMemo(
    () => (contractsByBundle.filter(
      (contract) => !ExcludedEngagementTypes.includes(contract.engagementType))
    ),
    [contractsByBundle],
  );

  return (
    <RequestActionModalContent
      isLoading={isLoading}
      onClose={onCloseModal}
      contracts={filteredContracts}
      title={requestAcceptedModalTitle}
      onClickConfirm={onRequestAccepted}
    />
  );
};

export default RequestAcceptedModal;

import React from 'react';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';
import EscalationSupportModalForm from 'pages/WirelineEngagements/shared/EscalationSupportModalForm';
import {
  escalationTypeOptions,
  escalationTypeCSOptions,
} from 'pages/WirelineEngagements/shared/EscalationSupportModalForm/constants';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';

const EscalationSupportModal = () => {
  const {
    activeRow,
    activeRowId,
    activeRowMode,
    cleanActiveRow,
  } = useTableActiveRowContext<ISowDataListItem | IContract>();

  const { isEscalationSupportMode } = getTableRowModeBoolean(activeRowMode);
  const escalationSupportRow = isEscalationSupportMode ? activeRow : undefined;

  const { CONTRACT_SPECIALIST } = UserTeamRoleTeamDepartment;
  const isContractSpecialist = useIsTeamRolesDepartmentMember(CONTRACT_SPECIALIST);
  const escalationTypesOptions = isContractSpecialist
    ? escalationTypeCSOptions
    : escalationTypeOptions;
  const escalationSupportRowId = isEscalationSupportMode ? activeRowId : undefined;

  return (
    <EscalationSupportModalForm
      cleanEscalationSupportRow={cleanActiveRow}
      escalationSupportRow={escalationSupportRow}
      escalationTypeOptions={escalationTypesOptions}
      escalationSupportRowId={escalationSupportRowId}
    />
  );
};

export default EscalationSupportModal;

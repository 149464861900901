import React, { useMemo, useEffect } from 'react';
import Loader from 'shared/Loader';
import { useParams } from 'react-router-dom';
import ContentShrink from 'shared/ContentShrink';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { useAppDispatch, useAppSelector } from 'core/store';
import { parseOpenapiSchema } from 'core/utilities/openapiSchema';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { getWidthToReduce } from 'pages/WirelineEngagements/shared/utilites';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import RenewalSowDataTable from '@EngagementsRenewalSowData/RenewalSowDataTable';
import { clearSalesRenewalSowData } from 'core/actions/Sales/GetSalesRenewalSow';
import getSalesRenewalSowAction from 'core/actions/Sales/GetSalesRenewalSow/actions';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { getColumnsFromMetaAndShchema } from 'core/utilities/getColumnsFromMetaAndShchema';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import BackToEditEngagementButton from 'pages/WirelineEngagements/shared/BackToEditEngagementButton';
import { validateEngagementAccepted } from '@EngagementsRenewalSowData/RenewalSowOrdersActions/utilities';
import { getSalesRenewalSowMetaAction, clearSalesRenewalSowMeta } from 'core/actions/Sales/GetSalesRenewalSowMeta';
import { columns, renewalSowDataTitle, dynamicRenewalProductSchemaName } from '@EngagementsRenewalSowData/constants';

import 'pages/WirelineEngagements/WirelineEngagementsTables/WirelineEngagementsTables.scss';
import './RenewalSowData.scss';

const RenewalSowDataContainer = () => {
  const { customerId } = useParams();
  const dispatch = useAppDispatch();

  const {
    isAvailableTableActions,
    isAvailableSomeRowAction,
    isAvailableRequestActions,
    isAvailableEngagementAccept,
  } = useRenewalSowPermissions();

  const openApiSchemas = useAppSelector((state) => state.openapiSchemas.schemas);
  const metaColumns = useAppSelector((state) => state.wirelineEngagements.renewalSowMeta.meta);
  const isLoadingMeta = useAppSelector(
    (state) => state.wirelineEngagements.renewalSowMeta.isLoading,
  );
  const isLoadingSchemas = useAppSelector((state) => state.openapiSchemas.isLoading);

  const retrieveSchema = useMemo(
    () => parseOpenapiSchema(openApiSchemas, dynamicRenewalProductSchemaName),
    [openApiSchemas],
  );

  const availableColumns = useMemo(
    () => getColumnsFromMetaAndShchema({ metaColumns, columns, retrieveSchema }),
    [metaColumns, retrieveSchema],
  );

  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext<IRenewalSowDataItem>();
  const { isEditMode, isViewMode, isCommentMode } = getTableRowModeBoolean(activeRowMode);

  const isTableShrank = isEditMode || isViewMode || isCommentMode;

  const enableRowSelection = isAvailableTableActions && ((row) => {
    const { cancelRequestDate } = row.original;

    const isСanceled = !!cancelRequestDate;
    const isEngagementAcceptDisabled = (isAvailableEngagementAccept && !isAvailableRequestActions)
      && validateEngagementAccepted(row.original);

    return !isСanceled && !isEngagementAcceptDisabled;
  });

  const getSalesRenewalSowMetaData = () => dispatch(getSalesRenewalSowMetaAction());

  useEffect(() => {
    getSalesRenewalSowMetaData();

    return () => {
      dispatch(clearSalesRenewalSowMeta());
      dispatch(clearSalesRenewalSowData());
    };
  }, []);

  if (!customerId) {
    return null;
  }

  if (isLoadingMeta || isLoadingSchemas) {
    return <Loader />;
  }

  return (
    <div className="renewal-sow">
      <BackToEditEngagementButton
        title={renewalSowDataTitle}
      />
      <ContentShrink
        isShrinked={isTableShrank}
        widthToReduce={getWidthToReduce(!!secondarySidebarMode)}
      >
        <TableProvider<string | number>
          id={tableIds.renewalSow}
          requestParams={customerId}
          columns={availableColumns}
          enableRowSelection={enableRowSelection}
          enableEditing={isAvailableSomeRowAction}
          getDataAction={getSalesRenewalSowAction}
        >
          <RenewalSowDataTable />
        </TableProvider>
      </ContentShrink>
    </div>

  );
};

export default RenewalSowDataContainer;

import React, { useState } from 'react';
import flareIcon from 'assets/images/flareLogo.svg';
import ChatWidget, { IMessage } from 'shared/ChatWidget';
import CommentIcon from '@mui/icons-material/ModeCommentOutlined';
import ToggleButton from 'layouts/Sidebar/ChatWidgetWrapper/ToggleButton';

import './ChatWidgetWrapper.scss';

const initialMessage: IMessage = {
  id: 'message-1',
  isBot: true,
  text: `Welcome to the FLAI Support Agent!
         I'm here to help you navigate and work efficiently in the system.
         Type your question below, and I'll guide you! 😊`,
};

const ChatWidgetWrapper = () => {
  const [messages, setMessages] = useState<IMessage[]>([initialMessage]);

  const menuItem = (
    isOpen, onToggleChat,
  ) => (<ToggleButton isOpen={isOpen} toggleHandler={onToggleChat} />);

  const brandIcon = <img src={flareIcon} alt="brand-icon" />;

  // TODO it's temporary function, remove after BE will be ready
  const sendAnswer = () => {
    const botReply = {
      id: Date.now(),
      isBot: true,
      text: "I'm here to help. Please describe your issue!",
    };
    setTimeout(() => {
      setMessages((prev) => [...prev, botReply]);
    }, 1000);
  };

  const onSendHandler = (text: string) => {
    const newMessage = { text, id: Date.now() };

    setMessages(
      (messagesList) => [...messagesList, newMessage],
    );

    sendAnswer();
  };

  return (
    <ChatWidget
      title="FLAI"
      messages={messages}
      brandIcon={brandIcon}
      onSend={onSendHandler}
      subtitle="Support Agent"
      renderChatLauncher={menuItem}
      classNameMessagesCount="messages_count"
      botIcon={<CommentIcon className="icon-bot" />}
    />
  );
};

export default ChatWidgetWrapper;

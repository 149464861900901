import { PERIOD_SELECT } from 'shared/PeriodAutocomplete/PeriodAutocomplete.models';

const periodMapping = {
  [PERIOD_SELECT.THREE_MONTHS]: { value: 2, unit: 'month' },
  [PERIOD_SELECT.SIX_MONTHS]: { value: 5, unit: 'month' },
  [PERIOD_SELECT.ONE_YEAR]: { value: 11, unit: 'month' },
  [PERIOD_SELECT.ONE_MONTH]: { value: 0, unit: 'month' },
  [PERIOD_SELECT.ONE_WEEK]: { value: 1, unit: 'week' },
};

export const periodMappingExcludeCurrent = {
  [PERIOD_SELECT.THREE_MONTHS]: { value: 3, unit: 'month' },
  [PERIOD_SELECT.SIX_MONTHS]: { value: 6, unit: 'month' },
  [PERIOD_SELECT.ONE_YEAR]: { value: 12, unit: 'month' },
  [PERIOD_SELECT.ONE_MONTH]: { value: 1, unit: 'month' },
  [PERIOD_SELECT.ONE_WEEK]: { value: 2, unit: 'week' },
};

export default periodMapping;

import { ESCALATION_TYPE } from '@EngagementsSowData/sowData.model';

export const escalationTypeOptions = [
  { value: ESCALATION_TYPE.PROJECT_MANAGER, label: 'Project Manager' },
  { value: ESCALATION_TYPE.CONTRACT_SPECIALIST, label: 'Contract Specialist' },
  { value: ESCALATION_TYPE.BILLING_SPECIALIST, label: 'Billing Specialist' },
  { value: ESCALATION_TYPE.SELLER, label: 'Seller' },
];

export const escalationTypeCSOptions = [
  { value: ESCALATION_TYPE.PROJECT_MANAGER, label: 'Project Manager' },
  { value: ESCALATION_TYPE.CONTRACT_SPECIALIST, label: 'Contract Specialist' },
  { value: ESCALATION_TYPE.SELLER, label: 'Seller' },
];

export const escalationTypeCMTableOptions = [
  { value: ESCALATION_TYPE.PROJECT_MANAGER, label: 'Project Manager' },
  { value: ESCALATION_TYPE.SELLER, label: 'Seller' },
];

export const escalationTypeRenewalOptions = [
  { value: ESCALATION_TYPE.CONTRACT_SPECIALIST, label: 'Contract Specialist' },
  { value: ESCALATION_TYPE.BILLING_SPECIALIST, label: 'Billing Specialist' },
  { value: ESCALATION_TYPE.SELLER, label: 'Seller' },
];

import getEngagementsRenewalOrders from '@dashboardWirelineSellerDepartment/GetEngagementsRenewalOrders/api.service';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import {
  getEngagementsRenewalOrdersFailure,
  getEngagementsRenewalOrdersRequest,
  getEngagementsRenewalOrdersSuccess,
} from './GetEngagementsRenewalOrders';

const getEngagementsRenewalOrdersAction = (
  controller: AbortController,
  params: TPeriod,
) => async (dispatch) => {
  try {
    const { signal } = controller;

    dispatch(getEngagementsRenewalOrdersRequest());

    const { data } = await getEngagementsRenewalOrders(signal, params);

    dispatch(getEngagementsRenewalOrdersSuccess(data));
  } catch (error) {
    dispatch(getEngagementsRenewalOrdersFailure(error));
  }
};

export default getEngagementsRenewalOrdersAction;

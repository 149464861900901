import { find, filter, compact } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { DASHBOARD_TABS } from 'pages/Dashboard/Dashboard.models';
import { WIRELINE_ENGAGEMENTS_TABS } from 'core/models/actionsPermissions';
import { dashboardRoutes, dashboardWirelineRoutes } from 'pages/Dashboard/routes';
// eslint-disable-next-line no-unused-vars
import { IRoutingConfigItem } from 'core/routing/models';
import routes from 'core/routing/routesConfig';
import accessNames from 'core/routing/accessRoutesNames';
import { useAppSelector } from 'core/store';
import { wirelineEngagementRoutes } from 'pages/WirelineEngagements/routes';

const excludeRouteList = [accessNames.dashboard, accessNames.wirelineEngagements];

export const useAvailableTabs = (keys: string[]) => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);

  return keys.reduce(
    (currentModules, key) => currentModules.find((item) => item.name === key)?.tabs || [],
    userProfileData?.authorizedModules || [],
  ).map((item) => item.name);
};

export const useAvailableSubRoutes = (subRoutes: IRoutingConfigItem[], keys: string[]) => {
  const tabs = useAvailableTabs(keys);

  return subRoutes.filter(({ id }) => !!id && tabs.includes(id));
};

const getDashboardRoute = (availableGeneralRoutes: IRoutingConfigItem[]) => {
  const dashboardRoute = find(availableGeneralRoutes, { accessName: accessNames.dashboard });
  const availableDashboardRoutes = useAvailableSubRoutes(dashboardRoutes, [accessNames.dashboard]);

  if (!dashboardRoute || isEmpty(availableDashboardRoutes)) { return dashboardRoute; }
  // getting child routes for dashboard
  const wirelineRoute = find(availableDashboardRoutes, { id: DASHBOARD_TABS.WIRELINE });
  const availableDashboardWirelineRoutes = useAvailableSubRoutes(
    dashboardWirelineRoutes, [accessNames.dashboard, DASHBOARD_TABS.WIRELINE],
  );

  // getting child routes for dashboard -> wireline
  if (!isEmpty(availableDashboardWirelineRoutes) && wirelineRoute) {
    const formattedWirelineRoute = {
      ...wirelineRoute,
      child: availableDashboardWirelineRoutes,
      redirect: availableDashboardWirelineRoutes[0].path,
    };

    const child = availableDashboardRoutes.map(
      (route) => (route?.id === DASHBOARD_TABS.WIRELINE ? formattedWirelineRoute : route),
    );
    const redirect = formattedWirelineRoute?.path;

    return {
      ...dashboardRoute,
      child,
      redirect,
    };
  }

  return {
    ...dashboardRoute,
    child: availableDashboardRoutes,
    redirect: availableDashboardRoutes[0].path,
  };
};

const getWirelineEngagementsRoute = (availableGeneralRoutes: IRoutingConfigItem[]) => {
  const wirelineEngagementsRoute = find(
    availableGeneralRoutes, { accessName: accessNames.wirelineEngagements },
  );

  const availableEngagementRoutes = useAvailableSubRoutes(
    wirelineEngagementRoutes,
    [accessNames.wirelineEngagements, WIRELINE_ENGAGEMENTS_TABS.ENGAGEMENT],
  );

  if (!wirelineEngagementsRoute || isEmpty(availableEngagementRoutes)) {
    return wirelineEngagementsRoute;
  }

  return {
    ...wirelineEngagementsRoute,
    child: [...(wirelineEngagementsRoute?.child || []), ...availableEngagementRoutes],
  };
};

export const useAvailableRoutes = () => {
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const isUserProfileData = !isEmpty(userProfileData.authorizedModules);

  if (!isUserProfileData) {
    return [];
  }

  const isAvailableRoute = (route: IRoutingConfigItem) => userProfileData.authorizedModules?.some(
    (item) => item.name === route.accessName,
  );

  const availableGeneralRoutes = routes.filter(isAvailableRoute);

  if (isEmpty(availableGeneralRoutes)) {
    return [];
  }

  const dashboardRoute = getDashboardRoute(availableGeneralRoutes);
  const wirelineEngagementsRoute = getWirelineEngagementsRoute(availableGeneralRoutes);

  const generalRoutes = filter(availableGeneralRoutes, ({
    accessName,
  }) => !excludeRouteList.includes(accessName as string));

  return compact([
    dashboardRoute,
    wirelineEngagementsRoute,
    ...generalRoutes,
  ]);
};

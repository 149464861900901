import React from 'react';
import get from 'lodash/get';
import { ISowDataListItem } from '@EngagementsSowData/sowData.model';
import { IActiveNumbersItem } from '@EngagementsActiveNumbers/activeNumbers.model';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import { IContract } from 'pages/ContractManagment/ContractManagement.models';

import './EscalationSupportInfo.scss';

interface IEscalationSupportInfoProps {
  isVisibleLocation?: boolean | undefined
  escalationSupportRow: ISowDataListItem | IRenewalSowDataItem | IActiveNumbersItem | IContract
}

const EscalationSupportInfo: React.FC<IEscalationSupportInfoProps> = ({
  isVisibleLocation,
  escalationSupportRow,
}) => {
  const location = isVisibleLocation ? (
    get(escalationSupportRow, 'customerLocation', '')
    || get(escalationSupportRow, 'location', '')
  ) as string : '';
  const product = get(escalationSupportRow, 'product', '');
  const customerName = get(escalationSupportRow, 'customerCompanyName', '') || get(escalationSupportRow, 'customerName', '');

  return (
    <div className="escalation-support__info-wrap">
      {
        isVisibleLocation
          && (
            <div className="escalation-support__info">
              <div className="escalation-support__info-name">Location</div>
              <div className="escalation-support__info-value">
                {location}
              </div>
            </div>
          )
      }
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Product</div>
        <div className="escalation-support__info-value">
          {product}
        </div>
      </div>
      <div className="escalation-support__info">
        <div className="escalation-support__info-name">Customer Name</div>
        <div className="escalation-support__info-value">
          {customerName}
        </div>
      </div>
    </div>
  );
};

EscalationSupportInfo.defaultProps = {
  isVisibleLocation: true,
};

export default EscalationSupportInfo;

import { RequestActionTypes } from 'pages/ContractManagment/ContractManagement.models';

export const requestsActionsButtonTitle = 'Requests Actions';

export const requestAcceptedModalTitle = 'Are you sure you want to accept selected request/s?';
export const requestCompleteModalTitle = 'Are you sure you want to complete the selected request/s?';
export const contractSpecialistTitle = 'Are you sure you want to change the Contract Specialist for the selected request/s?';
export const requestCompletedCannotBeCompletedTooltip = 'This request cannot be completed because the required data is missing. Please ensure all mandatory fields are filled.';
export const requestActionTitles = {
  [RequestActionTypes.ACCEPTED]: requestAcceptedModalTitle,
  [RequestActionTypes.COMPLETED]: requestCompleteModalTitle,
};

export const requestAcceptedButtonText = {
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
};

export const requestAcceptedNotifier = {
  success: 'Request was accepted successfully.',
  error: 'Request failed.',
};

export const requestCompletedNotifier = {
  success: 'Requests have been completed successfully.',
  error: 'Failed to complete the selected requests.',
};

export const contractSpecialistNotifier = {
  success: 'Contract Specialist has been changed successfully.',
  error: 'Failed to change the Contract Specialist.',
};

export const buttonWidth = '105px';

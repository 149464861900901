import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import EngagementsByPeriodChart from '@dashboardWirelineDepartments/shared/EngagementsByPeriodChart';
import { notifierMessage } from '@dashboardWirelineDepartments/shared/constants';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import {
  getEngagementsOrdersTOByPeriodAction,
} from '@dashboardWirelineDepartments/TechnicalOverlayDepartment/actions/GetEngagementsOrdersTOByPeriod';
import useCancelableRequest from 'core/hooks/useCancelableRequest';

const TOEngagementsByPeriodChart = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();
  const { createNewController } = useCancelableRequest(HTTPService);
  const engagementsByPeriod = useAppSelector((state) => state.dashboard.engagementsTOByPeriod.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsTOByPeriod.isLoading);

  const getEngagementsByPeriodData = async (newPeriod) => {
    try {
      const engagementsByPeriodController = createNewController();
      await dispatch(getEngagementsOrdersTOByPeriodAction(
        { controller: engagementsByPeriodController, params: newPeriod },
      ));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsByPeriod.error, e);
    }
  };

  return (
    <EngagementsByPeriodChart
      isLoading={isLoading}
      list={engagementsByPeriod}
      onPeriodChange={getEngagementsByPeriodData}
    />
  );
};

export default TOEngagementsByPeriodChart;

import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { ACTIONS_WIRELINE_ENGAGEMENTS_PATHS, ACTIONS_WIRELINE_ENGAGEMENT_PATHS } from 'core/models/actionsPermissions';

const { ORDER_CREATE } = ACTIONS_WIRELINE_ENGAGEMENT_PATHS;
const {
  ENGAGEMENT_EDIT,
  ENGAGEMENT_CREATE,
} = ACTIONS_WIRELINE_ENGAGEMENTS_PATHS;

const actionsPaths = {
  isAvailableCreateOrder: ORDER_CREATE,
  isAvailableEditEngagement: ENGAGEMENT_EDIT,
  isAvailableCreateEngagement: ENGAGEMENT_CREATE,
};

export const useEngagementsFormPermission = () => {
  const {
    isAvailableCreateOrder,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  } = useActionsPermissions(actionsPaths);

  return {
    isAvailableCreateOrder,
    isAvailableEditEngagement,
    isAvailableCreateEngagement,
  };
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { exportRenewalSowData } from 'core/api/sales.api';
import { useTableContext } from 'shared/Table/TableContext';
import ExportTableXlsxFile from 'shared/ExportTableXlsxFile';
// eslint-disable-next-line no-unused-vars
import { IRenewalSowDataItem, IExportRenewalSowRequestParams } from '@EngagementsRenewalSowData/renewalSowData.models';

interface IExportRenewalSowTableProps {
  sowDataItem?: IRenewalSowDataItem
}

const ExportRenewalSowTable: React.FC<IExportRenewalSowTableProps> = ({ sowDataItem }) => {
  const { tableFilters } = useTableContext();
  const { customerId } = useParams();

  const { pagination: { pageSize, pageIndex } } = tableFilters;
  const { customerCompanyName } = sowDataItem || {};
  const exportFileName = customerCompanyName ? `${customerCompanyName}_Renewal.xlsx` : 'Renewal.xlsx';

  const exportParams = {
    requestParams: {
      customerId: customerId!,
    },
    filtersParams: {
      page: pageIndex + 1,
      page_size: pageSize,
    },
  };

  return (
    <ExportTableXlsxFile<IExportRenewalSowRequestParams>
      params={exportParams}
      fileName={exportFileName}
      exportAction={exportRenewalSowData}
    />
  );
};

ExportRenewalSowTable.defaultProps = {
  sowDataItem: {} as IRenewalSowDataItem,
};

export default ExportRenewalSowTable;

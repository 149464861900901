/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IEngagementsTotalItem } from 'pages/Dashboard/Dashboard.models';
// eslint-disable-next-line no-unused-vars
import { TPeriod } from 'shared/CustomRangePicker/CustomRangePicker.models';
import { getEngagementType } from '@dashboardWirelineContractSpecialistDepartment/GetEngagementType/api.service';

export interface IState {
  data: IEngagementsTotalItem[];
  isLoading: boolean;
  error: object | null;
}

const initialState: IState = {
  data: [],
  error: null,
  isLoading: false,
};

export const getEngagementTypeAction = createAsyncThunk(
  'getEngagementTypeAction',
  async ({ params, controller }: { params: TPeriod, controller: AbortController },
    { rejectWithValue }) => {
    try {
      const { signal } = controller;
      const { data } = await getEngagementType(signal, params);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const EngagementTypeSlice = createSlice({
  name: 'getEngagementType',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getEngagementTypeAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEngagementTypeAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(getEngagementTypeAction.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.data = [];
      });
  },
});

export const getEngagementTypeReducer = EngagementTypeSlice.reducer;

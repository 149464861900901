import React from 'react';
import cx from 'classnames';
import CustomTooltip from 'shared/CustomTooltip';
import { Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TooltipPlacement } from 'shared/CustomTooltip/customTooltip.models';

import './OrderModalItem.scss';

interface IOrderModalItemProps {
  // eslint-disable-next-line no-restricted-globals
  location: string
  product: string
  accepted?: boolean
  withTooltip?: boolean | undefined
  tooltipTitle?: string
  className?: string
}

const OrderModalItem: React.FC<IOrderModalItemProps> = ({
  product,
  location,
  accepted,
  withTooltip,
  tooltipTitle,
  className,
}) => (
  <CustomTooltip
    isHidden={!withTooltip}
    title={tooltipTitle as string}
    placement={TooltipPlacement.TOP}
  >
    <div className={cx('order-modal-item-wrap', { accepted }, className)}>
      <div className="order-modal-item-adres-wrap">
        <CheckCircleOutlineIcon className={cx('order-modal-item-icon', { accepted })} />
        <Tooltip title={location} placement="top">
          <Typography
            noWrap
            variant="inherit"
            className="order-modal-item-adres"
          >
            {location}
          </Typography>
        </Tooltip>
      </div>
      <Tooltip title={product} placement="top">
        <Typography
          noWrap
          variant="inherit"
          className="order-modal-item-product"
        >
          {product}
        </Typography>
      </Tooltip>
    </div>
  </CustomTooltip>
);

OrderModalItem.defaultProps = {
  tooltipTitle: '',
  accepted: false,
  withTooltip: true,
  className: '',
};

export default OrderModalItem;

import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store';
import EngagementsByPeriodChart from '@dashboardWirelineDepartments/shared/EngagementsByPeriodChart';
import { HTTPService } from 'core/services';
import { notifierMessage } from '@dashboardWirelineDepartments/shared/constants';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import getEngagementsOrdersByPeriodAction
  from '@dashboardWirelineSellerDepartment/GetEngagementsOrdersByPeriod/actions';
import useCancelableRequest from 'core/hooks/useCancelableRequest';

const SellerEngagementsByPeriodChart = () => {
  const dispatch = useAppDispatch();
  const { showErrorNotifier } = useNotifiers();
  const { createNewController } = useCancelableRequest(HTTPService);

  const engagementsByPeriod = useAppSelector((state) => state.dashboard.engagementsByPeriod.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementsByPeriod.isLoading);

  const getEngagementsByPeriodData = async (newPeriod) => {
    try {
      const engagementsByPeriodController = createNewController();
      await dispatch(getEngagementsOrdersByPeriodAction(
        engagementsByPeriodController, newPeriod,
      ));
    } catch (e) {
      showErrorNotifier(notifierMessage.engagementsByPeriod.error, e);
    }
  };

  return (
    <EngagementsByPeriodChart
      isLoading={isLoading}
      list={engagementsByPeriod}
      onPeriodChange={getEngagementsByPeriodData}
    />
  );
};

export default SellerEngagementsByPeriodChart;

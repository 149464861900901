import React, { useState, useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { HTTPService } from 'core/services';
import useNotifiers from 'shared/Notifier/UseNotifiers';
import FiltersByPeriod from 'pages/Dashboard/shared/FiltersByPeriod';
import { useActionsPermissions } from 'core/hooks/useActionsPermissions';
import { defaultPeriod } from '@dashboardWirelinePMDepartment/constants';
import BookingInfo from '@dashboardWirelineContractSpecialistDepartment/BookingInfo';
import { notifierMessage } from '@dashboardWirelineContractSpecialistDepartment/constants';
import EngagementTypeChart from '@dashboardWirelineContractSpecialistDepartment/EngagementTypeChart';
import { ACTIONS_DASHBOARD_WIRELINE_CONTRACT_SPECIALIST_PATHS } from 'core/models/actionsPermissions';
import { getEngagementTypeAction } from '@dashboardWirelineContractSpecialistDepartment/GetEngagementType';
import ContractRequestTypeChart from '@dashboardWirelineContractSpecialistDepartment/RequestsTypeChart';
import TotalOrderTypeChart
  from '@dashboardWirelineContractSpecialistDepartment/TotalOrderTypeChart/TotalOrderTypeChart';
import TIMELINE_TO_COMPLETION_STATUS_CHART
  from '@dashboardWirelineContractSpecialistDepartment/TimelineToCompletionStatusChart';
import useCancelableRequest from 'core/hooks/useCancelableRequest';

import './ContractSpecialistDepartment.scss';

const {
  BOOKING_TABLE_GET,
  ENGAGEMENT_TYPE_CHART_GET,
} = ACTIONS_DASHBOARD_WIRELINE_CONTRACT_SPECIALIST_PATHS;

const actionsPaths = {
  isAvailableBookingTable: BOOKING_TABLE_GET,
  isAvailableEngagementTypeChart: ENGAGEMENT_TYPE_CHART_GET,
};

const ContractSpecialistDepartment = () => {
  const { showErrorNotifier } = useNotifiers();
  const dispatch = useAppDispatch();
  const { createNewController } = useCancelableRequest(HTTPService);

  const {
    isAvailableBookingTable,
    isAvailableEngagementTypeChart,
  } = useActionsPermissions(actionsPaths);

  const [period, setPeriod] = useState(defaultPeriod);

  const getTransactionalOrders = async (newPeriod) => {
    const engagementTypeController = createNewController();

    try {
      dispatch(getEngagementTypeAction({
        controller: engagementTypeController, params: newPeriod,
      })).unwrap();
    } catch (error) {
      showErrorNotifier(notifierMessage.engagementType.error, error);
    }
  };

  const updateCharts = (newPeriod) => {
    getTransactionalOrders(newPeriod);

    setPeriod(newPeriod);
  };

  useEffect(() => {
    updateCharts(period);
  }, []);

  return (
    <div className="contract-specialist">
      <div className="contract-specialist__filters">
        <FiltersByPeriod
          period={period}
          onChange={updateCharts}
        />
      </div>
      <div className="contract-specialist__charts">
        {
          isAvailableEngagementTypeChart && <EngagementTypeChart />
        }
        <ContractRequestTypeChart />
      </div>
      <div className="contract-specialist__charts">
        <TotalOrderTypeChart />
        <TIMELINE_TO_COMPLETION_STATUS_CHART />
      </div>
      {
        isAvailableBookingTable && (
        <div className="contract-specialist__boking-info">
          <BookingInfo />
        </div>
        )
      }
    </div>
  );
};

export default ContractSpecialistDepartment;

import React from 'react';
import { useIsBrand } from 'core/hooks/useIsBrand';
import { BRAND_NAMES } from 'core/whiteLabel/whiteLabel';
import BookingInfo from '@dashboardWirelineSellerDepartment/BookingInfo';
import TechnicalOverlay from '@dashboardWirelineSellerDepartment/TechnicalOverlay';
import SellerRenewalChart from '@dashboardWirelineSellerDepartment/SellerRenewalChart';
import SellerEngagementsMrcChart from '@dashboardWirelineSellerDepartment/SellerEngagementsMrcChart';
import { useSellerDepartmentContext } from '@dashboardWirelineSellerDepartment/SellerDepartmentContext';
import SellerEngagementsTotalsChart from '@dashboardWirelineSellerDepartment/SellerEngagementsTotalsChart';
import SellerEngagementsRevenueChart from '@dashboardWirelineSellerDepartment/SellerEngagementsRevenueChart';
import SellerEngagementsByPeriodChart from '@dashboardWirelineSellerDepartment/SellerEngagementsByPeriodChart';
import SellerFiltersByPeriod from 'pages/Dashboard/BusinessDirections/Wireline/WirelineDepartments/SellerDepartment/SellerFiltersByPeriod';

import './SellerDepartment.scss';

const SellerDepartmentContent = () => {
  const { actionsPermissions } = useSellerDepartmentContext();
  const isFlareBrand = useIsBrand(BRAND_NAMES.FLARE);

  const {
    isAvailableGetMRCChart,
    isAvailableGetTotalChart,
    isAvailableGetRenewalChart,
    isAvailableGetBookingTable,
    isAvailableGetByPeriodChart,
    isAvailableGetByRevenueChart,
    isAvailableGetTechnicalOverlayChart,
  } = actionsPermissions;

  const isVisibleTechnicalOverlayChart = isAvailableGetTechnicalOverlayChart && !isFlareBrand;

  return (
    <div className="seller-department">
      <SellerFiltersByPeriod />
      <div className="seller-department__charts">
        {isAvailableGetTotalChart && <SellerEngagementsTotalsChart />}
        {isAvailableGetRenewalChart && <SellerRenewalChart />}
        {isAvailableGetByPeriodChart && <SellerEngagementsByPeriodChart />}
        {isAvailableGetMRCChart && <SellerEngagementsMrcChart />}
        {isAvailableGetByRevenueChart && <SellerEngagementsRevenueChart />}
      </div>
      {isVisibleTechnicalOverlayChart && <TechnicalOverlay />}
      {
        isAvailableGetBookingTable && (
          <div className="seller-department__boking-info">
            <BookingInfo />
          </div>
        )
      }
    </div>
  );
};

export default SellerDepartmentContent;

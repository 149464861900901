import React from 'react';
import { TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import DataRowSidebar from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DataRowSidebar';
import CommentsSidebar from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/CommentsSidebar';
import CreateOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/CreateOrderModal';
import CancelOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/CancelOrderModal';
import DeclineOrderModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/DeclineOrderModal';
import EditRowSidebarForm from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EditRowSidebarForm';
import EscalationSupportModal from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/EscalationSupportModal';

interface IRenewalSowDataTableModalsProps {
  createOrderModalType: TAddOrderType
  setCreateOrderModalType: (value: TAddOrderType) => void
}

const RenewalSowDataTableModals: React.FC<IRenewalSowDataTableModalsProps> = ({
  createOrderModalType,
  setCreateOrderModalType,
}) => {
  const {
    isAvailableEditOrder,
    isAvailableCreateOrder,
    isAvailableGetComments,
    isAvailableCancelOrder,
    isAvailableGetOrderData,
    isAvailableDeclineRenewal,
    isAvailableCreateEscalationSupport,
  } = useRenewalSowPermissions();

  return (
    <>
      { isAvailableEditOrder && <EditRowSidebarForm /> }
      { isAvailableGetOrderData && <DataRowSidebar /> }
      { isAvailableGetComments && <CommentsSidebar /> }
      { isAvailableCancelOrder && <CancelOrderModal /> }
      { isAvailableDeclineRenewal && <DeclineOrderModal /> }
      { isAvailableCreateEscalationSupport && <EscalationSupportModal /> }
      {
        isAvailableCreateOrder && (
          <CreateOrderModal
            createOrderModalType={createOrderModalType}
            setCreateOrderModalType={setCreateOrderModalType}
          />
        )
      }
    </>
  );
};

export default RenewalSowDataTableModals;

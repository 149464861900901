import React, {
  useState, ReactElement, useEffect, useRef,
} from 'react';
import cx from 'classnames';
import { IMessage } from 'shared/ChatWidget';
import Message from 'shared/ChatWidget/ChatWindow/Message';
import { useUpdateEffect } from 'core/hooks/useUpdateEffect';
import ChatInput from 'shared/ChatWidget/ChatWindow/ChatInput';
import ChatHeader from 'shared/ChatWidget/ChatWindow/ChatHeader';

interface IChatWindowProps {
  title: string
  value: string
  isOpen: boolean
  subtitle: string
  onSend: () => void
  messages: IMessage[]
  toggleChat: () => void
  onChange: (value: string) => void
  botIcon?: ReactElement | undefined
  brandIcon?: ReactElement | undefined
  classNameHeader?: string | undefined
  isHideFullscreen?: boolean | undefined
  classNameMessages?: string | undefined
  classNameInputBlock?: string | undefined
  classNameChatWrapper?: string | undefined
}

const ChatWindow: React.FC<IChatWindowProps> = ({
  title,
  value,
  isOpen,
  onSend,
  botIcon,
  messages,
  subtitle,
  onChange,
  brandIcon,
  toggleChat,
  classNameHeader,
  isHideFullscreen,
  classNameMessages,
  classNameInputBlock,
  classNameChatWrapper,
}) => {
  const [isFullscreenMode, setFullscreenMode] = useState(false);
  const toggleFullscreen = () => setFullscreenMode(
    (currentFullscreenMode) => !currentFullscreenMode,
  );

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = (behavior: ScrollBehavior) => {
    if (!isOpen || !messagesEndRef.current) { return; }

    messagesEndRef.current.scrollIntoView({ behavior });
  };

  useEffect(() => {
    scrollToBottom('auto');
  }, [isOpen]);

  useUpdateEffect(() => {
    scrollToBottom('smooth');
  }, [messages]);

  return (
    <div className={cx(
      'cw-chat-window',
      classNameChatWrapper,
      { 'cw-chat-window__fullscreen': isFullscreenMode })}
    >
      <ChatHeader
        title={title}
        subtitle={subtitle}
        brandIcon={brandIcon}
        toggleChat={toggleChat}
        className={classNameHeader}
        isHideFullscreen={isHideFullscreen}
        toggleFullscreen={toggleFullscreen}
      />
      <div className={cx('cw-chat-messages', classNameMessages)}>
        {messages.map((message) => (
          <Message
            botIcon={botIcon}
            message={message}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <ChatInput
        value={value}
        onSend={onSend}
        onChange={onChange}
        className={classNameInputBlock}
      />
    </div>
  );
};

ChatWindow.defaultProps = {
  botIcon: undefined,
  classNameHeader: '',
  brandIcon: undefined,
  classNameMessages: '',
  isHideFullscreen: false,
  classNameInputBlock: '',
  classNameChatWrapper: '',
};

export default ChatWindow;

import React, { useState } from 'react';
import Table from 'shared/Table';
import isEmpty from 'lodash/isEmpty';
import { useAppSelector } from 'core/store';
import CreateButton from 'shared/CreateButton';
import { TRow } from 'shared/Table/Table.models';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTableContext } from 'shared/Table/TableContext';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { IRenewalSowDataItem } from '@EngagementsRenewalSowData/renewalSowData.models';
import RenewalSowOrdersActions from '@EngagementsRenewalSowData/RenewalSowOrdersActions';
import { useRenewalSowPermissions } from '@EngagementsRenewalSowData/useRenewalSowPermission';
import { ADD_ORDER_TYPE, TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';
import ExportRenewalSowTable from '@EngagementsRenewalSowData/RenewalSowDataTable/ExportRenewalSowTable';
import RenewalSowDataTableRowActions from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableRowActions';
import { colDefOptionSelect, colDefOptionActionsTwoIcons, colDefOptionActionsSingleIcon } from 'shared/Table/constants';
import RenewalSowDataTableModals from '@EngagementsRenewalSowData/RenewalSowDataTable/RenewalSowDataTableModals/RenewalSowDataTableModals';
import {
  defaultColumnPinning,
  muiTableContainerProps,
} from 'pages/WirelineEngagements/WirelineEngagementsTables/constants';

const RenewalSowDataTable = () => {
  const { rowSelection } = useTableContext();
  const { activeRowMode, secondarySidebarMode } = useTableActiveRowContext();
  const { isEditMode, isViewMode } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableCreateOrder,
    isAvailableTableActions,
    isAvailableSomeRowAction,
    isAvailableTwoRowActions,
    isAvailableExportTableData,
  } = useRenewalSowPermissions();

  const isRenewalSowLoading = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.isLoading,
  );

  const renewalSowData = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.results,
  );

  const renewalSowCount = useAppSelector(
    (state) => state.wirelineEngagements.renewalSow.count,
  );

  const [createOrderModalType, setCreateOrderModalType] = useState<TAddOrderType>(null);
  const createOrderActionText = secondarySidebarMode ? <MoreHorizIcon /> : 'add order';
  const isCreateOrderButtonDisabled = isEditMode
   || isViewMode
   || !isEmpty(rowSelection);

  const openCreateOrderModal = () => setCreateOrderModalType(ADD_ORDER_TYPE.RENEWAL_ORDER);

  const createOrderAction = isAvailableCreateOrder ? (
    <CreateButton
      className="create-order-btn"
      text={createOrderActionText}
      onClick={openCreateOrderModal}
      disabled={isCreateOrderButtonDisabled}
    />
  ) : undefined;

  const actionsButton = isAvailableTableActions ? <RenewalSowOrdersActions /> : undefined;

  const renderBottomToolbarCustomActions = isAvailableExportTableData ? () => (
    <ExportRenewalSowTable sowDataItem={renewalSowData[0]} />
  ) : undefined;

  return (
    <>
      <Table
        showGlobalFilter
        enableStickyHeader
        className="wireline-table"
        showColumnFilters={false}
        rowCount={renewalSowCount}
        actionsButton={actionsButton}
        isLoading={isRenewalSowLoading}
        toolBarAction={createOrderAction}
        rows={renewalSowData as unknown as TRow[]}
        initialColumnPinning={defaultColumnPinning}
        muiTableContainerProps={muiTableContainerProps}
        renderRowActions={({ row: { original } }) => (
          <RenewalSowDataTableRowActions
            row={original as unknown as IRenewalSowDataItem}
          />
        )}
        displayColumnDefOptions={{
          ...(isAvailableTableActions && colDefOptionSelect),
          ...(isAvailableSomeRowAction && colDefOptionActionsSingleIcon),
          ...(isAvailableTwoRowActions && colDefOptionActionsTwoIcons),
        }}
        renderBottomToolbarCustomActions={renderBottomToolbarCustomActions}
      />
      <RenewalSowDataTableModals
        createOrderModalType={createOrderModalType}
        setCreateOrderModalType={setCreateOrderModalType}
      />
    </>
  );
};
export default RenewalSowDataTable;

import React from 'react';
import { useParams } from 'react-router-dom';
import { firstPage } from 'shared/Table/constants';
import { useTableContext } from 'shared/Table/TableContext';
import AddOrderModalForm from 'pages/WirelineEngagements/shared/AddOrderModalForm';
import { TAddOrderType } from 'pages/WirelineEngagements/WirelineEngagements.models';

interface ICreateOrderModalProps {
  createOrderModalType: TAddOrderType
  setCreateOrderModalType: (value: TAddOrderType) => void
}

const CreateOrderModal: React.FC<ICreateOrderModalProps> = ({
  createOrderModalType,
  setCreateOrderModalType,
}) => {
  const { customerId } = useParams();
  const { setPagination, getTableData } = useTableContext();

  const onAddProductSuccessCallback = () => {
    setPagination((paging) => ({
      ...paging,
      pageIndex: firstPage,
    }));

    getTableData();
  };

  return (
    <AddOrderModalForm
      customerId={Number(customerId)}
      orderModalType={createOrderModalType}
      setAddOrderModalType={setCreateOrderModalType}
      onSubmitSuccessCallback={onAddProductSuccessCallback}
    />
  );
};

export default CreateOrderModal;

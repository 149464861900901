import React from 'react';
import { useAppSelector } from 'core/store';
import DashboardPieChart from 'pages/Dashboard/shared/DashboardPieChart';
import { ENGAGEMENT_TYPES_LABELS, colorsOfengagementTypeCharts } from '@dashboardWirelineContractSpecialistDepartment/constants';

const EngagementTypeChart = () => {
  const engagementTypeData = useAppSelector((state) => state.dashboard.engagementType.data);
  const isLoading = useAppSelector((state) => state.dashboard.engagementType.isLoading);

  const chartData = engagementTypeData.map((item) => ({
    ...item,
    name: ENGAGEMENT_TYPES_LABELS[item.name],
  }));

  return (
    <DashboardPieChart
      innerRadius={60}
      paddingAngle={5}
      data={chartData}
      isLoading={isLoading}
      title="Engagement Type"
      className="engagements-type"
      colors={colorsOfengagementTypeCharts}
    />
  );
};

export default EngagementTypeChart;

import React, { useEffect } from 'react';
import { useAppDispatch } from 'core/store';
import { tableIds } from 'shared/Table/constants';
import { TableProvider } from 'shared/Table/TableContext';
import { columns } from '@dashboardWirelineContractSpecialistDepartment/BookingInfo/constants';
import BookingTable from '@dashboardWirelineContractSpecialistDepartment/BookingInfo/BookingTable';
import { clearBookingCSDataAction } from '@dashboardWirelineContractSpecialistDepartment/GetBookingCSData';
import getBookingCSDataAction from '@dashboardWirelineContractSpecialistDepartment/GetBookingCSData/actions';

import './BookingInfo.scss';

const BookingInfo = () => {
  const dispatch = useAppDispatch();

  useEffect(() => () => {
    dispatch(clearBookingCSDataAction());
  }, []);

  return (
    <TableProvider
      columns={columns}
      getDataAction={getBookingCSDataAction}
      id={tableIds.bookingContractSpecialistInfo}
    >
      <BookingTable />
    </TableProvider>
  );
};

export default BookingInfo;

import React from 'react';
import compact from 'lodash/compact';
import Actions from 'shared/Table/Actions';
import { orderActionTitle } from 'core/labels';
import CommentIcon from '@mui/icons-material/Comment';
import OrderDataIcon from '@mui/icons-material/DescriptionOutlined';
import NextWeekOutlinedIcon from '@mui/icons-material/NextWeekOutlined';
import { getTableRowModeBoolean } from 'shared/Table/Actions/utilities';
import { useTableActiveRowContext } from 'shared/Table/TableActiveRowContext';
import { EngagementType, IContract } from 'pages/ContractManagment/ContractManagement.models';
import { useContractManagementPermissions } from 'pages/ContractManagment/useContractManagementPermission';

interface ContractManagementRowActionsProps {
    row: IContract;
}

const ContractManagementRowActions: React.FC<
    ContractManagementRowActionsProps
> = ({ row }) => {
  const {
    activeRowId,
    activeRowMode,
    setActiveRowEdit,
    setActiveRowView,
    setActiveRowComment,
    setActiveRowOrderData,
    setActiveRowEscalationSupport,
  } = useTableActiveRowContext();

  const {
    isDataMode, isCommentMode, isEscalationSupportMode,
  } = getTableRowModeBoolean(activeRowMode);

  const {
    isAvailableEditOrder,
    isAvailableGetOrderData,
    isAvailableGetLogAndFiles,
    isAvailableGetEscalationSupport,
  } = useContractManagementPermissions();

  const { id: rowId } = row;

  const isPending = row.engagementType === EngagementType.PENDING;
  const isCompleted = row.engagementType === EngagementType.COMPLETED;
  const isCanceled = row.engagementType === EngagementType.CANCELED;
  const isDeclined = row.engagementType === EngagementType.DECLINED;
  const isInProgress = row.engagementType === EngagementType.IN_PROGRESS;
  const isLogAndFilesButtonSelected = isCommentMode && rowId === activeRowId;
  const isOrderDataButtonSelected = isDataMode && rowId === activeRowId;
  const isEscalationSupportButtonSelected = isEscalationSupportMode && rowId === activeRowId;

  const isLogAndFilesButtonDisabled = !!activeRowId && !isLogAndFilesButtonSelected;
  const isOrderDataButtonDisabled = !!activeRowId && !isOrderDataButtonSelected;
  const isEscalationSupportButtonDisabled = !isInProgress || (
    !!activeRowId && !isEscalationSupportButtonSelected
  );

  const isMoreButtonDisabled = isLogAndFilesButtonDisabled
    && isOrderDataButtonDisabled
    && isEscalationSupportButtonDisabled;
  const isActiveButtonDisabled = !isAvailableEditOrder || isPending;

  const isViewButton = isCanceled || isCompleted || isDeclined;

  const moreButtonConfig = compact([
    (isAvailableGetLogAndFiles && {
      text: 'Log&Files',
      icon: <CommentIcon />,
      isDisabled: isLogAndFilesButtonDisabled,
      onClick: () => setActiveRowComment(row),
    }),
    (isAvailableGetOrderData && {
      text: 'Order Data',
      icon: <OrderDataIcon />,
      isDisabled: isOrderDataButtonDisabled,
      onClick: () => setActiveRowOrderData(row),
    }),
    (isAvailableGetEscalationSupport && {
      text: 'Escalation Support',
      icon: <NextWeekOutlinedIcon />,
      isDisabled: isEscalationSupportButtonDisabled,
      onClick: () => setActiveRowEscalationSupport(row),
    }),
  ]);

  return (
    <Actions<IContract>
      row={row}
      activeRowId={activeRowId}
      isViewButton={isViewButton}
      activeRowMode={activeRowMode}
      setActiveRowEdit={setActiveRowEdit}
      setActiveRowView={setActiveRowView}
      moreButtonConfig={moreButtonConfig}
      moreButtonTooltipTitle={orderActionTitle}
      isMoreButtonDisabled={isMoreButtonDisabled}
      isActionButtonVisible={isAvailableEditOrder}
      isActiveButtonDisabled={isActiveButtonDisabled}
    />
  );
};

export default ContractManagementRowActions;

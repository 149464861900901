import React from 'react';
import cx from 'classnames';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';

import './ToggleButton.scss';

interface IToggleButtonProps {
  isOpen: boolean
  toggleHandler: () => void
}

const ToggleButton = ({ isOpen, toggleHandler }) => (
  <button
    type="button"
    onClick={toggleHandler}
    className={cx('toggle_button', { active: isOpen })}
  >
    <div className="toggle_button__icon-wrapper">
      <LiveHelpOutlinedIcon />
    </div>
    <span>Help & Tips</span>
  </button>
);

export default ToggleButton;

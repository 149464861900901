import useIsUserTeamRoleName from 'core/hooks/useIsUserTeamRoleName';
import useIsTeamRolesDepartmentMember from 'core/hooks/useIsTeamRolesDepartmentMember';
import { UserTeamRoleName, UserTeamRoleTeamDepartment } from 'pages/UserManagement/UserManagement.models';

const { DEPARTMENT_HEAD } = UserTeamRoleName;

const {
  BILLING_SPECIALIST, PROJECT_MANAGER,
} = UserTeamRoleTeamDepartment;

export const useSowDataActionsButtonPermissions = () => {
  const isDepartmentHead = useIsUserTeamRoleName(DEPARTMENT_HEAD);

  const isProjectManager = useIsTeamRolesDepartmentMember(PROJECT_MANAGER);
  const isBillingSpecialist = useIsTeamRolesDepartmentMember(BILLING_SPECIALIST);

  const isProjectManagerNotDepartmentHead = isProjectManager && !isDepartmentHead;

  const isUnavailableActionsBuntton = isBillingSpecialist
  || isProjectManagerNotDepartmentHead;

  return {
    isUnavailableActionsBuntton,
  };
};

import React from 'react';
import {
  colorsOfengagementTypeCharts,
  REQUEST_TYPE_LABELS,
} from '@dashboardWirelineContractSpecialistDepartment/constants';
import {
  mockRequestsTypeData,
} from '@dashboardWirelineContractSpecialistDepartment/GetRequestsTypeChart/mockRequestsTypeData';
import { map } from 'lodash';
import DashboardBarChart from 'pages/Dashboard/shared/DashboardBarChart';
import { LabelPosition } from 'core/models/Recharts.models';

const ContractRequestTypeChart = () => {
  const barLabel = {
    label: { position: LabelPosition.TOP },
  };

  const formattedData = map(mockRequestsTypeData, (item) => ({
    xDateKey: item.name,
    ...item.value,
  }));

  return (
    <DashboardBarChart
      data={formattedData}
      colors={colorsOfengagementTypeCharts}
      fields={REQUEST_TYPE_LABELS}
      isLoading={false}
      title="Requests Type"
      barLabel={barLabel}
      needInsideLabel
    />
  );
};

export default ContractRequestTypeChart;
